import Stomp from 'stompjs';
import SockJS from 'sockjs-client';
import { stompConfig } from '../res/constants';

class _SocketClient {
  socket = {};
  stompClient = {}
  constructor() {
    this.connect.bind(this)
  }

  connect = () => {    
    return new Promise((resolve, reject) => {
      if(!localStorage.getItem('token')) {
        reject('User not connected ...')
        return
      }

      // console.log('Token : ', stompConfig);

      this.socket = new SockJS(stompConfig.url);
  
      this.stompClient = Stomp.over(this.socket);
      // this.stompClient.debug = stompConfig.debug;
    
      this.stompClient.connect(stompConfig.headers, (frame) => {
        console.log('Frame', frame);
        resolve(this.stompClient);
      }, (error) => {
        console.log('Error promise stompClient : ', error);
        // window.location.reload();
        // setTimeout(this.connect, 1000);
        this.connect()
        // reject(error);
      });
    });
  };

  send(topic, body) {
    this.stompClient.send(topic, {}, JSON.stringify(body));
  }

}

const SocketClient = new _SocketClient();


export default SocketClient;