import React, { useState, useEffect } from 'react';
import { Form, Modal } from 'react-bootstrap';
import add_panier from '../../assets/add_shopping_cart_icon.png';
import orders_online from '../../assets/orders/online-order.png';

import NumericInput from 'react-numeric-input';
import ReactLoading from 'react-loading';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ordersStatusPut } from '../../services/stores/actions/orders';
import { messageRequestPaid } from '../../services/stores/actions/orders';
import { toast } from 'react-toastify';
import paypalLogo from '../../assets/payment/paypal-226456.svg'
import masterCard from '../../assets/payment/mastercard-226450.svg'

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

export function ModalPaid(props) {
	const [show, setShow] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [selectedValue, setSelectedValue] = useState('/paymentpaypal')

	const [error, setShowError] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleSelectedValue = (event) => {
		// const link = event.target.value === 'PAYPAL' ? '/paymentpaypal' : '/PaymentCard'
		setSelectedValue(event.target.value)
	}

	const formik = useFormik({
		initialValues: {
			price: '',
			description: '',
			client_id: props.client_id,
		},
		validationSchema: Yup.object({
			price: Yup.string().required('Entrer your price'),
			description: Yup.string().required('Entrer the request description'),
			client_id: Yup.string().required('Entrer client_id'),
		}),

		onSubmit(values) {
			console.log('Client id ', props.client_id);
			messageRequestPaid(
				{
					id: props.client_id,
					...values,
				}
			).then(
				(response) => {
					// data_pi : panier Id
					values.data_pi = response.data.data;
					values.status = null
					props.cdm(values);
					formik.resetForm()
					handleClose();
				},
				(error) => {
					console.log(`ERROR`, error);
				}
			);
		},
	});

	return (
		<>
			<MonetizationOnIcon
				className="inputMessageIcon"
				onClick={handleShow}
			/>

			<Modal
				show={show}
				onHide={handleClose}
				size="sm"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header className="row mx-0 justify-content-center">
					<img
						src={orders_online}
						width={`50px`}
						alt=''
						height={`50px`}
						className={`${props.label === 'Decliner' && 'd-none'}`}
					/>
					<p className="text-center k-semibold-20 w-100 pt-2">
						{props.label === 'Decliner'
							? 'Confirm order declination'
							: props.label === 'Decliner' ? 'Order shipment' : 'Request payment'
						}
					</p>
				</Modal.Header>
				{showLoading && (
					<div
						style={{
							position: 'absolute',
							top: '40%',
							left: '42%',
							zIndex: 999,
						}}
						className="w-75 text-center">
					</div>
				)}
				<Form className="border-0 w-100" onSubmit={formik.handleSubmit}>
				{/* <Form className="border-0 w-100"> */}
					{props.label === 'RequestPaid' && (
						<Modal.Body style={{marginLeft:'0px'}}>
							<Form.Group className="mb-3" controlId="price">
								<Form.Label>Price</Form.Label>
								<Form.Control
									type="number"
									name="price"
									placeholder="price"
									className="rounded-pill"
									{...formik.getFieldProps('price')}
								/>{' '}
								{formik.touched.price && formik.errors.price ? (
									<div className="text-danger">
										{formik.errors.price}
									</div>
								) : null}
							</Form.Group>
							<Form.Group className="mb-3" controlId="description">
								<Form.Label>Description</Form.Label>
								<Form.Control
									type="text"
									name="description"
									placeholder="Request description"
									className="rounded-pill"
									{...formik.getFieldProps('description')}
								/>{' '}
								{formik.touched.description &&
								formik.errors.description ? (
									<div className="text-danger">
										{formik.errors.description}
									</div>
								) : null}
							</Form.Group>
						</Modal.Body>
					)}

					<Modal.Footer style={{marginLeft:'0px'}}>
						<div className="col-12" >
							<button
								className={`btn btn-${props.button} btn-rounded k-regular-15 btn-block `}
								style={{ border: 'none' }}
								type="submit"
							>
								{props.label === 'RequestPaid' && 'Request payment'}
							</button>

							<button
								className="btn btn-light btn-rounded k-regular-15 btn-block"
								style={{ border: 'none' }}
								type="button"
								onClick={() => {
									formik.resetForm();
									handleClose();
								}}>
								Cancel
							</button>
						</div>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
}
