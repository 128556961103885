import moment from 'moment';

export const getSizeOfObject = (obj) => {
	const objectLenght = Object.keys(obj).length;
	return objectLenght;
};

export const fileToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		file && reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export const momentGetDiff = (date) => {
	var date = moment(date).format('MM/DD/YYYY HH:mm');
	var today = moment();

	var secondDiff = today.diff(date, 'second');
	var minuteDiff = today.diff(date, 'minute');
	var hourDiff = today.diff(date, 'hour');
	var dayDiff = today.diff(date, 'day');
	var monthDiff = today.diff(date, 'month');
	var yearDiff = today.diff(date, 'year');
	if (secondDiff <= 60 && secondDiff >= 0) {
		return `${secondDiff} seconds`;
	} else if (minuteDiff < 60) {
		return `${minuteDiff} minutes`;
	} else if (hourDiff < 24) {
		return `${hourDiff} days`;
	} else if (dayDiff < 30) {
		return `${dayDiff} days`;
	} else if (monthDiff <= 12) {
		return `${monthDiff} month`;
	} else if (yearDiff >= 1) {
		return `${yearDiff} year`;
	}
};
