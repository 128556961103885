import React, { Component } from 'react';
import './MessagesDetails.css';
// material icons
import ImageIcon from '@material-ui/icons/Image';
import close from '../../assets/close.png';
// import ProgressBar from 'react-progress-bar'; 
import {
	Popover,
	OverlayTrigger,
	InputGroup,
	FormControl,
} from 'react-bootstrap';
import ic_search from '../../assets/ic_search.svg';
import musicDefaultPreview from '../../assets/musicDefaultPreview.svg'
import points from '../../assets/profil/points.svg';
import signaler from '../../assets/profil/signaler.png';
import bloquer from '../../assets/profil/bloquer.png';
import defaultProfile from '../../assets/profil/utilisateur.png';
import Message from '../../components/Message/Message';
import { Link } from 'react-router-dom';
import StickyFooter from '../../components/Footer/StickyFooter/StickyFooter';
import Select from 'react-dropdown-select';
import {
	getAllNewRequestFriend,
	searchUser,
	searchUserByPseudo,
	userGetListBlocker,
} from '../../services/stores/actions/compte';
import {
	getAllDicussions,
	getAllMessagesFor,
	newMessage,
	setSelectedDiscussion,
	showAllDiscussion,
	showMessages,
	setUpdateDiscussion,
	refreshDiscussion,
} from '../../services/stores/actions/chat';
import { FileUploader } from 'react-drag-drop-files';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { InfiniteScrolling } from '../../components/InfiniteScroll/InfiniteScroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
	ModalDeleteMessage,
} from '../../components/Modal/ModalMessage';
import {
	SwipeAction,
	TrailingActions,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import { momentGetDiff } from '../../services/stores/actions/size';
import WS from '../../services/stores/actions/api';
import { api } from '../../res/constants';
import { ModalPaid } from '../../components/Modal/ModalPaid';
import Followup from '../../components/Followup/Followup';
import SocketClient from '../../utils/socketjs';

export class MessagesDetails extends Component {

	constructor(props) {
		super(props);
		this.myRefWeb = React.createRef();
		this.myRefMobile = React.createRef();
		this.state = {
			newMessage: '',
			date: new Date(),
			messageList: [],
			selectedMessage: false,
			openNewMessage: false,
			isBlocked: false,
			isLoading:false,
			newMessageUser: '',
			newReceivers: [],
			users: [],
			allUsers: [],
			listRequestFriends: [],
			friends: [],
			allDiscussions: [],
			listBlocked: [],
			searchInput: '',
			resultSearchUser: [],
			messageSorted: [],
			selectDiscussion: [],
			inputList: [],
			updateDiscussion: false,
			picture: null,
			errors: null,
			listFile: [],
			items: [],
			images: [],
			hasMore: true,
			currentPage: 1,
			paymentintent: '',
			isWeb: true,
			isLoadingMessage: false,
			page: 1,
			loadFetch : false,
		};
		this.inputRefs = React.createRef();
		this.modalRefs = React.createRef();
		this.submitMessage = this.submitMessage.bind(this);
	}

	static defaultProps = {
		messageData: {
			id: null,
			name: '',
			content: [],
			files: [],
			followed: null,
			status: '',
			img: defaultProfile,
		},
	};

	componentDidMount() {
		// this.sort(this.props.discussions);
		this.setState({ isLoadingMessage: this.props.history.location.isLoadingMessage })
		this.setState({ updateDiscussion: this.props.updateDiscussion });

		searchUser(null, 100).then((res) => {
			this.setState({ users: res.data.data.content });
		});

		// this.getAllDiscussions();
		userGetListBlocker().then((res) => {
			this.setState({ userListBlocked: res.data });
		});

// --------------------
		let urlType = this.props.match.params.id
		console.log('init detail: ', urlType);
		this.getAllDiscussions(urlType);

		// if (idReceiver === 'admin') {
		// 	idReceiver = '643eab1e367e10ae448d88b3';
		// }
		// this.checkIfDiscussionGroupExist(idReceiver);


		// let idReceiver = this.props.match.params.id
		// if (idReceiver) {
		// 	if (idReceiver === 'new-group') {
		// 		const query = new URLSearchParams(this.props.location.search);
		// 		let aaa = query.get('ids')
		// 		this.openNewMessage();
		// 		const e = [
		// 			{
		// 				label: 'new-group',
		// 				pseudo: 'new-group',
		// 				value: aaa,
		// 			}
		// 		]
		// 		this.setState({ newReceivers: e });
		// 		this.props.setSelectedDiscussion(null);
		// 		this.setState({allDiscussions: null})
		// 	} else {
		// 		if (idReceiver === 'admin') {
		// 			idReceiver = '643eab1e367e10ae448d88b3';
		// 		}
		// 		this.checkIfDiscussionGroupExist(idReceiver);
		// 		// this.checkIfDiscussionExist(idReceiver);
		// 		// if (window.innerWidth < 767) {
		// 		// 	this.setState({isWeb: false})
		// 		// }
		// 	}
		// }
	}

	setPI(values) {
		this.submitMessage(JSON.stringify(values))
	}

	async checkIfDiscussionGroupExist(idReceiver) {
		// console.log('hahahahah');
		// idReceiver += 
		// this.setState({ isLoadingMessage: true })
		WS.axiosGetWithToken({
			endpoint: `/discussions/check-group-users?userIds=${idReceiver}`
		}).then(
			(res) => {
				console.clear()
				console.log('response group: ', res);
				this.setState({ isLoadingMessage: false })
				if (res.data?.data?.discussion) {
					console.log('discussion existe: ', res.data.data.discussion);
					this.getAllMessages(res.data.data);
				} else { // CREER UNE DISCUSSION
					console.log('discussion nouveau: ', res);
					this.openNewMessage();
					const e = []
					res.data.data.subject.forEach(element => {
						e.push({label: element.pseudo, pseudo: element.pseudo, value: element.id})
					});
					console.log('newReceivers: ', e);

					this.setState({ newReceivers: e });
					this.props.setSelectedDiscussion(null);
					this.setState({allDiscussions: null})
				}
			},
			(error) => {
				// console.log('ERROR GET ALL USERS : ', error);
				// reject(error);
			}
		);
	}

	async checkIfDiscussionExist(idReceiver) {
		this.setState({ isLoadingMessage: true })
		WS.axiosGetWithToken({
			endpoint: `${api.discussionByUserId}/${idReceiver}`
		}).then(
			(res) => {
				console.log('discussion existe: ', res);
				this.setState({ isLoadingMessage: false })
				if (res.data.data.discussion) {
					this.getAllMessages(res.data.data);
				} else { // CREER UNE DISCUSSION
					console.log('fsdfghdsgfhjsgfdshj');
					this.openNewMessage();
					const e = [
						{	label: res.data.data.user?.pseudo || res.data.data.subject.pseudo,
							pseudo: res.data.data.user?.pseudo || res.data.data.subject.pseudo,
							value: res.data.data.user?.id || res.data.data.subject.id,
						}
					]
					this.setState({ newReceivers: e });
					this.props.setSelectedDiscussion(null);
					this.setState({allDiscussions: null})
				}
			},
			(error) => {
				console.log('discussion not exist: ');
				// console.log('ERROR GET ALL USERS : ', error);
				// reject(error);
			}
		);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.updateDiscussion !== this.props.updateDiscussion) {this.componentDidMount();}
	}

	componentWillUnmount() {
		this.props.setSelectedDiscussion(null)
	}

	// SwipeableList , on swipe right
	trailingActions = (discussionId) => (
		<TrailingActions>
			<SwipeAction
				destructive={true}
				onClick={() => {
					// problem render all time from listing discussion
					// this.modalRefs.current();
				}}>
				<ModalDeleteMessage
					label={`Delete discussion`}
					modalRefs={this.modalRefs}
					child={
						<button className="btn btn-danger btn-rounded k-regular-15">
							Delete
						</button>
					}
					discussionId={discussionId}
					cdm={() => this.componentDidMount()}
				/>
			</SwipeAction>
		</TrailingActions>
	);

	// Create New discussion so search user
	searchUser = (pseudo) => {
		searchUserByPseudo(pseudo).then((response) => {
			this.setState({
				friends: response.data.data.content[0],
			});
		});
	};

	handleChange = (event) => {this.setState({ newMessageUser: event.target.value });};

	// Comment
	handleChangeCommentInput = (event) => {this.setState({ newMessage: event.target.value });};

	// Open new dialog message
	openDialogMessage = () => {
		if (this.state.newMessageUser.length) {
			let newUser = this.state.newMessageUser;
			return this.setState({ newMessageUser: newUser });
		}
	};

	// Get all discussion from props redux
	getAllDiscussions = (urlType) => {
		getAllDicussions().then((data) => {
			this.props.showAllDiscussions(data.data.content);
			// this.props.setSelectedDiscussion(null);
			this.props.showMessages([]);
			this.setState({ allDiscussions: data.data.content });

			console.log('this.state.allDiscussions: ', data.data.content);
			console.log('urltype: ', urlType);
			const query = new URLSearchParams(this.props.location.search);
			if (urlType === 'discussion') {
				console.log('get discussion');
				if (query.get('id')) {
					let discIsExist = this.state.allDiscussions.filter(elt => elt.discussion.id == query.get('id'));
					if (discIsExist.length) {
						this.props.setSelectedDiscussion(discIsExist[0]);
						this.getAllMessages(discIsExist[0])
					}
				}
			}
			else if (urlType === 'new') {
				console.log('idu: ');
				if (query.get('idu')) {
					this.checkIfDiscussionExist(query.get('idu'))
				}
			}
			else if (urlType === 'new-group') {
				console.log('idus: ');
				if (query.get('idus')) {
					this.checkIfDiscussionGroupExist(query.get('idus'))
				}
			}
			else {
				// this.props.setSelectedDiscussion(null);
				this.checkIfDiscussionExist(urlType)
			}

		})
		.catch((_err) => { });

		getAllNewRequestFriend().then((res) => { this.setState({ listRequestFriends: res.data.data }); });
	};

	fetchMoreData = () => {
		if (this.props.messages.length >= 500) {
			this.setState({ hasMore: false });
			return;
		}
		setTimeout(() => {
			this.setState({ items: this.state.items.concat(Array.from({ length: 20 })), });
		}, 1500);
	};

	fetchMoreData2 = () => {
		console.log('renew call');
		// this.setState({items: [...this.state.items, ...[11,12,13,14,15,16,17,18,19,20]]})
		const  discussionId  = this.props.discussion?.discussion?.id;
		console.log("ID discussion ---->", this.props.discussion);
		console.log("discussionId---->", discussionId);
		const nextPage = this.state.page + 1;
		getAllMessagesFor(discussionId, nextPage)
		.then((data) => {
			console.log("Call API---->",data);				

			// Convert the data into an array of messages
			const newMessages = data.data.content.map((message) => {
				return ({
					id: message.id,
					img: message.sender.profile ? message.sender.profil : defaultProfile,
					content: message.message,
					files: message.files,
					isSentByCurrentUser:this.props.userConnected && message.sender.id === this.props.userConnected.id,
					time: message.createdAt,
				})
			});
			// Append new messages to the existing messages array
			console.log('sms---> ', this.props.messages);
			const updatedMessages  = [...newMessages, ...this.props.messages ];
			console.log("updated message---->",updatedMessages);
			this.props.showMessages(updatedMessages);
			this.scrollToLastMsg();
			this.setState({ page: nextPage });

				if(updatedMessages.length === this.props.messages.length){
					this.setState({ hasMore : false, loadFetch : false })
				} else {
					this.setState({loadFetch : false });
				}
			})
			.catch((error) => {
				console.log('Error fetching more messages:', error);
			}
		);
		console.log("first groupe of 15 message",this.props.messages);
		// console.log(this.props.discussion.discussion.id);
		};

	getAllMessages = (discussion) => {
		getAllMessagesFor(discussion?.discussion?.id).then((msgs) => {
			const formattedMsgs = msgs.data.content.map((msg) => ({
				id: msg.id,
				img: msg?.sender?.profil ? msg?.sender?.profil : defaultProfile,
				time: msg.createdAt,
				content: msg.message,
				isSentByCurrentUser: msg.sender.id === this.props.user.id,
				files: msg.files
			}));
			this.setState({ isLoadingMessage: false })
			this.props.showMessages(formattedMsgs);
			this.setState({ openNewMessage: false });
			this.props.setSelectedDiscussion(discussion);
			this.scrollToLastMsg()
		})
		.catch((_err) => {});
	};

	selectDiscussionOnMobile = () => {
		if (window.innerWidth < 767) {
			this.setState({isWeb: false})
		}
	}

	// Signal , Block user, delete discussion
	onRenderPopOver = (image, taille, pseudo, userId, label, discussionId) => {
		var isBlocked = false;
		// eslint-disable-next-line no-lone-blocks
		{
			this.state.userListBlocked?.map((res) => {
				if (res.user === userId) {
					isBlocked = true;
				} else {
					isBlocked = false;
				}
			});
		}
		const popover = (
			<Popover id="popover-basic">
				<Popover.Content>
					{label === 'signal' ? (
						<div className="d-flex flex-column">
							{/* <Link
								style={{ textDecoration: 'none', color: 'black' }}
								to={{
									pathname: `/formSignaler/${pseudo}`,
									state: { id: userId, origin: `user`, contentId: userId },
								}}>
								<img src={signaler} alt="" /> Signaler
							</Link> */}
							<Link
								style={{ textDecoration: 'none', color: 'black' }}
								to={{
									pathname: `/formSignaler/${pseudo}`,
									state: { id: userId, origin: `user`, contentId: userId },
								}}>
								<img src={signaler} alt="" /> Report
							</Link>

							<Link
								style={{ textDecoration: 'none', color: 'black' }}
								to={{
									pathname: `/formBloquer/${pseudo}`,
									state: {
										id: userId,
										reason: `${isBlocked ? 'debloquer' : 'bloquer'}`,
										pseudo: pseudo,
									},
								}}>
								<img src={bloquer} alt="" />{' '}
								{isBlocked ? 'Debloquer' : 'Bloquer'}
							</Link>
						</div>
					) : (
						// <div className="d-flex flex-column">
						// 	<ModalDeleteMessage
						// 		label={`Supprimer discussion`}
						// 		discussionId={discussionId?.discussion?.id}
						// 		cdm={() => this.componentDidMount()}
						// 	/>
						// </div>
						<div className="d-flex flex-column">
							{/* <ModalDeleteMessage
								label={`Delete chat`}
								discussionId={discussionId?.discussion?.id}
								cdm={() => this.componentDidMount()}
							/> */}
						</div>
					)}
				</Popover.Content>
			</Popover>
		);

		return (
			<OverlayTrigger
				trigger="click"
				placement="bottom"
				overlay={popover}
				rootClose>
				<img
					src={image}
					alt=""
					style={{ width: taille + 'px', height: taille + 'px' }}
					className="ml-1"
				/>
			</OverlayTrigger>
		);
	};

	selectMessage = () => {
		this.setState({ selectedMessage: !this.state.selectedMessage });
	};

	// Tag User
	customItemRenderer = ({ item, itemIndex, props, state, methods }) => (
		<div className="p-2">
			<div onClick={() => methods.addItem(item)}>
				<img src={item.avatar} alt="" className="mr-2" />
				{item.label}
			</div>
		</div>
	);

	//  Result search user web
	options = () => {
		return this.state.users.map((user) => ({
			label: `${user.pseudo}`,
			pseudo: `${user.pseudo}`,
			value: user.id,
		}));
	};

	// Result search user mobile version
	optionsMobile = () => {
		return this.state.searchInput.map((user) => ({
			label: `${user.pseudo}`,
			pseudo: `${user.pseudo}`,
			value: user.id,
		}));
	};

	// Select user for mobile
	optionsSelectMobile = () => {
		return this.state.messageSorted.map((user) => ({
			label: `${user.user.firstName} ${user.user.lastName}`,
			pseudo: `${user.user.pseudo}`,
			value: user.discussion.id,
			lastMessage: user.discussion.lastMessage.message,
			selectedDiscussion: user.discussion,
		}));
	};

	// Sort message by Datetime
	sort = (obj) => {
		// var tab = obj;
		// tab.sort(function (a, b) {
		// 	var keyA = a.discussion.updatedAt,
		// 		keyB = b.discussion.updatedAt;
		// 	// Compare the 2 dates
		// 	if (keyA < keyB) return -1;
		// 	if (keyA > keyB) return 1;
		// 	return tab;
		// });
		// this.setState({ messageSorted: tab });
	};

	render() {
		return (
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-2 d-none d-lg-block px-3">
						<div style={{ position: 'absolute', bottom: 0 }}>
							<StickyFooter />
						</div>
					</div>
					<div className="col-lg-8 col-md-12">
						<div className="MessagesContainer">
							<div className="d-flex flex-md-row mx-0">
								{/*  Right Message input */}
								<div className="col-md-7 p-0 p-md-3 discussions-details">
									{this.renderDiscussionMessage(
										this.fomatDicussionForOpenMessage()
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	// Opening Existing discussion
	fomatDicussionForOpenMessage = () => {
		if (this.props.selectedDiscussion) {
			return {
				id: this.props.selectedDiscussion.discussion?.id,
				idUser: this.props.selectedDiscussion.user?.id,
				pseudo: this.props.selectedDiscussion.user?.pseudo,
				profil: this.props.selectedDiscussion.user?.profil,
				name: `${this.props.selectedDiscussion.user?.firstName} ${this.props.selectedDiscussion.user?.lastName}`,
				content: this.props.selectedDiscussion.discussion?.lastMessage?.message,
				followed:
					this.props.selectedDiscussion.friend_request &&
					this.props.selectedDiscussion.friend_request.status === 'VALIDATED',
			};
		} else {
			return {
				id: null,
				idUser: this.state.newReceivers.id,
				pseudo: this.state.newReceivers.pseudo,
				profil: null,
				name: this.state.newReceivers.label,
			};
		}
		return {};
	};

	// Select existing discussion
	onRenderSearchForm2 = () => {
		return (
			<div className="inputSearch">
				<InputGroup
					className="mb-3"
					style={{
						borderRadius: '125rem',
						border: 'solid 1px #C4C4C6',
					}}>
					<div className="new-user-wapper w-100 k-regular-15">
						<Select
							placeholder="Search"
							className="k-regular-15"
							style={{
								border: '0',
								borderRadius: '0rem 25rem 25rem 0',
							}}
							onChange={(e) => {
								this.openDialogMessage();
								this.getAllMessages(e[0].discussion);
								this.setState({ openNewMessage: false });
								this.props.setSelectedDiscussion(e[0].discussion);
							}}
							options={this.optionsWeb()}
						/>
					</div>
				</InputGroup>
			</div>
		);
	};

	// select user web in searchbar
	optionsWeb = () => {
		return (
			this.props.discussions &&
			this.props.discussions.map((discussion) => ({
				label: `${discussion.user.firstName} ${discussion.user.lastName}`,
				pseudo: `${discussion.user.pseudo}`,
				value: discussion.discussion.id,
				discussion: discussion,
			}))
		);
	};

	// Format each discussion
	formatDiscussion = (discussionDTO) => ({
		id: discussionDTO.discussion.id,
		idUser: discussionDTO.user.id,
		img: discussionDTO.user.profil || defaultProfile,
		name: `${discussionDTO.user.firstName} ${discussionDTO.user.lastName}`,
		followed:
			discussionDTO.friend_request &&
			discussionDTO.friend_request.status === 'VALIDATED',
		content: discussionDTO.discussion.lastMessage.message,
		status: momentGetDiff(discussionDTO.discussion.lastMessage.createdAt),
		userPseudo: discussionDTO.user.pseudo,
		friend_request: discussionDTO.friend_request,
		receiverMessage: discussionDTO.discussion.receiver,
		item: discussionDTO,
		isSeen: discussionDTO.isSeen,
		unreadMessage: discussionDTO.notSeenMessages
	});

	contentPrivate = (contentData) => {
		return contentData?.includes('"data_pi":') ? 'private content' : contentData?.substring(0, 10)
	}

// Listing Discussion on  Web left side and mobile
	renderListMessage = ({
		id,
		idUser,
		img = defaultProfile,
		name = 'Utilisateur MyBeeDoo',
		userPseudo = 'Utilisateur MyBeeDoo',
		followed = true,
		content = '',
		status = '',
		receiverMessage = null,
		friend_request = null,
		item = null,
		isSeen = false,
		unreadMessage = 1
	}) => {
		return (
			<div className={`d-flex flex-row mx-0 user-details py-2`}>
				<div className="col-md-12 col-sm-12 px-2">
					<div className="d-flex flex-row mx-0 p-0 k-regular-15 message-content">
						<img
							src={img}
							width="35px"
							height="35px"
							className="rounded-circle mr-1"
							alt=""
						/>
						<div className="col">
							<div className="d-flex flex-row mx-0 k-regular-15 text-dark">
								<span className="k-semibold-15">
									{userPseudo.substring(0, 15)}
								</span>
								{friend_request && friend_request.status === 'VALIDATED' && (
									<span className="pl-2 text-muted"></span>
								)}
							</div>
							<div className="d-flex flex-row mx-0 k-regular-15 text-muted align-items-center">
								{
									<>
										{ !isSeen && (
											<span className='mr-1' style={
												{ 
													width: '15px',
													height: '15px',
													fontSize: '12px',
													borderRadius: '50%', 
													backgroundColor: 'rgb(220, 53, 69)',
													alignItems: 'center',
													justifyContent: 'center',
													color: 'white',
													display: 'flex'
												}}>{unreadMessage < 10 ? unreadMessage : '+9'}</span>
										) }
										<span className="last-message"  style={{ fontWeight: isSeen ? `` : `bold`, color: isSeen ? `` : `#000000ba` }}>
											{this.contentPrivate(content)}
										</span>
									</>
								}
								<span className="pl-2 text-muted k-regular-13 pr-0">
									{status}
								</span>
							</div>
						</div>
						<div>
							<Followup
								friend_up={friend_request}
								id={item.user.id}
								label={'Add'}
								isConnected={this.props.isConnected}
								pseudo={item.user.pseudo}
								setStateOfParent={() => this.componentDidMount()}
								setShowLoading={() => {}}
							></Followup>
						</div>
					</div>
				</div>
			</div>
		);
	};

	// Main Discussion
	renderDiscussionMessage = ({
		id,
		idUser,
		name = null,
		content,
		followed,
		pseudo,
		profil = defaultProfile,
	}) => {
		// console.log('In render discussion message', this.props.refreshDiscussion);
		if(this.props.refreshDiscussion) {
			this.getAllMessages(this.props?.selectedDiscussion)
			this.props.setRefreshDiscussion(false)
		}
		return (
			<div className="renderDiscussionMessage h-100 mt-1" style={{position: 'relative'}}>
				<div style={{height: '44px'}}>
					{this.state.openNewMessage ? (
						<div className="row mx-0 discussion-header px-0 pt-1 container-fluid">
							<InputGroup className={`mb-3 rounded-pill border`}>
								<InputGroup.Prepend
									style={{ width: '15%' }}
									className="pt-2 pl-3">
									At :
								</InputGroup.Prepend>
								<div
									className="d-flex flex-row new-user-wapper"
									style={{ width: '85%' }}>
									<Select
										placeholder="Search"
										multi
										ref={this.myRefWeb}
										dropdownHandleRenderer={() => null}
										style={{
											fontFamily: 'KohinoorRegular',
											border: '0',
											borderRadius: '0rem 25rem 25rem 0',
											width: '100%',
											marginTop: '4px',
										}}
										itemRenderer={this.customItemRenderer}
										onChange={(e) => {
											this.setState({ newReceivers: e })
											console.log('newReceivers: ', this.state.newReceivers);
										}}
										values={this.state.newReceivers}
										options={this.options()}
									/>
								</div>
							</InputGroup>
						</div>
					) : (
						<div className="row mx-0 discussion-header px-0 pt-1 container-fluid">
							<div className="col pl-2">
								<Link>
								</Link>
								<div className="row mx-0 pointer-cursor">
									<div>
										{this.props?.selectedDiscussion && (

										<Link
											style={{ decoration: 'none', color: 'black' }}
											to={{
												pathname: `/details-user/${pseudo}`,
												state: {
													id: idUser,
												},
											}}>
											<img
												src={profil ? profil : defaultProfile}
												alt=""
												className="img-fluid rounded-circle rounded_35"
											/>
										</Link>
										)}
									</div>
									<div className="col">
										{!name ? null : (
											<Link
												style={{ decoration: 'none', color: 'black' }}
												to={{
													pathname: `/details-user/${pseudo}`,
													state: {
														id: idUser,
													},
												}}>
												{this.props.selectedDiscussion?.user?.pseudo}
											</Link>
										)}

										{followed ? (
											<span className="pl-2 text-muted">Subscriber</span>
										) : null}
										{this.props?.selectedDiscussion?.user?.status === 'ACTIVED' && (
											<div className="row mx-0 text-muted k-regular-13 pr-0">
												On line
											</div>
										)}
									</div>
								</div>
							</div>
							<div className="float-right pointer-cursor">
								{this.props?.selectedDiscussion &&
									this.onRenderPopOver(
										points,
										31,
										this.props?.selectedDiscussion?.user?.pseudo,
										this.props?.selectedDiscussion?.user?.id,
										'signal'
									)}
							</div>
						</div>
					)}
				</div>
				
				<div className="discussionList">
					<div className="col-md-12 messageListsXXX h-100">
						{/* <InfiniteScrolling
							isLoadingMessage={this.state.isLoadingMessage}
							className="discussionList2"
							discussion={ this.props.selectedDiscussion }
							messages={this.props.messages}
							profil={profil}
							refreshMessage={() => this.getAllMessages(this.props.selectedDiscussion)}
						/> */}
						<div
									id="scrollableDiv"
									style={{
										height: 410,
										// height : window.innerHeight - 250,
										// width: ,
										overflow: 'auto',
										display: 'flex',
										flexDirection: 'column-reverse',
									}}
								>
									{/*Put the scroll bar always on the bottom*/}
									<InfiniteScroll
										// dataLength={this.state.items.length}
										dataLength={this.props.messages.length}
										next={this.fetchMoreData2}
										style={{ display: 'flex', flexDirection: 'column-reverse', overflowX : 'hidden', overflowY: 'hidden' }} //To put endMessage and loader to the top.
										inverse={true}
										hasMore={true}
										// loader={<h4>Loading...</h4>}
										loader={<h6 style={{textAlign: 'center', backgroundColor: 'rgb(255, 224, 92)'}}>{this.state.loadFetch ? 'Loading...' : 'first message'}</h6>}
										scrollableTarget="scrollableDiv"
									>
										{/* {this.state.items.map((_, index) => (
											<div key={index} style={{width:600}}>
												div - #{index}
											</div>
										))} */}
										{[...this.props.messages].reverse().map((item, index) => (
											<>
												<Message
													key={item.id || index}
													refreshMessage={this.props.refreshMessage}
													messageId={item.id}
													userImg={item.img}
													isSentByCurrentUser={item.isSentByCurrentUser}
													time={momentGetDiff(item.time)}
													content={item.content}
													files={ item.files }
												/>
												<div style={{height: '30px'}}></div>
											</>
										))}
											
									</InfiniteScroll>
								</div>
					</div>
				</div>

				<div
					style={{
						position: 'absolute',
						bottom: '0px',
						backgroundColor: '#0000005f',
					}}
					className="row mx-0 justify-content-start w-100">
					{this.state.listFile.map((elt, ind) => (
						<div className="col-4 col-md-2">
							<div
								style={{
									position: 'absolute',
									right: '0px',
									top: '-15px',
									margin: '10px 10px',
									width: '25px',
									height: '25px',
								}}
								className="rounded-circle text-center pointer-cursor"
								onClick={() => this.handleRemoveClickPhoto(ind)}>
								<img
									src={close}
									alt=""
									width={'16px'}
									className="rounded-circle"
									style={{ backgroundColor: 'white' }}
								/>
							</div>
							{elt.includes('video/mp4') ? (
								<video
									src={elt}
									alt=""
									width={'100%'}
									height={'100px'}
									className="my-2"
									autoPlay
									muted
									onClick={() => this.handleRemoveClickPhoto(ind)}
									style={{ objectFit: 'cover' }}
									controlsList="nodownload"
									onContextMenu={e => e.preventDefault()}
									onLoadedData={() => this.setState({ isLoading: false })}
								/>
							) : (
								<img
								src={elt.includes('image') ? elt : musicDefaultPreview}
									alt=""
									width={'100%'}
									height={'100px'}
									className="my-2"
									style={{ objectFit: 'contain' }}
								/>
							)}
						</div>
					))}
				</div>

				<div className="inputMessageText" style={{bottom: '0', backgroundColor: '#fff'}}>
					{this.inputMessage(idUser)}
				</div>
			</div>
		);
	};

	// Remove photo selection
	handleRemoveClickPhoto = (index) => {
		let list = [...this.state.inputList];
		list.splice(index, 1);
		this.setState({ inputList: list });
		// remove the picture from list base 64
		let listFiletmp = [...this.state.listFile];
		listFiletmp.splice(index, 1);
		this.setState({ listFile: listFiletmp });
	};

	// Upload file in discussion input file
	handleUploadFile = (event) => {
		const maxSize = 400 * 1024 * 1024; // 40 MB in bytes
		let reader = new FileReader();
		let file = event;
		if (file.size > maxSize) {
			// Afficher un message d'erreur ou prendre des mesures appropriées
			// console.log("File size exceeds the maximum limit (40 MB).");
			toast.error('File size exceeds the maximum limit (400 MB).', {
				position: toast.POSITION.TOP_CENTER,
				// *style: { color: 'red', backgroundColor: 'transparent', marginTop: '150px' }
			});
			return;
		}
		if (this.state.isLoading) {
			toast.info('File upload is already in progress.', {
			  position: toast.POSITION.TOP_CENTER,
			});
			return;
		}
		this.setState({ errors: false , isLoading: true});
		// this.setState({ errors: false , isLoading: true, uploadProgress: 0});
		reader.onloadend = () => {
			const list = [...this.state.inputList];
			const listFileTmp64 = [...this.state.listFile];
			list.push(file);
			listFileTmp64.push(reader.result);
			// this.setState({ inputList: list, listFile: listFileTmp64 }, () => {
			// 	this.setState({ isLoading: false });
			// });
			this.setState({ inputList: list });
			this.setState({ listFile: listFileTmp64 });
			this.setState({ isLoading: false });
		};
		reader.onprogress = (event) => {
			if (event.lengthComputable) {
				const progress = Math.round((event.loaded / event.total) * 100);
				// Mettre à jour le state du chargement
				this.setState({ uploadProgress: progress });
			}
		};
		// reader.onload = () => {
		// 	this.setState({ isLoading: false });
		// };
		if (file) {
			reader.readAsDataURL(file);
		}
	};

	// Step 2 Press onKeyDown
	inputMessage = (item) => {
		// const { isLoading , uploadProgress} = this.state;
		return (
			<div className="inputMessageBox w-100" style={{position: 'absolute', bottom: '0px !important'}}>
				<InputGroup
					style={{
						borderRadius: '125rem',
						border: 'solid 1px #303030',
						width: '100%',
						height: '40px'
					}}>
					<div className="d-flex align-items-center">
						<ModalPaid
							label="RequestPaid"
							button="danger"
							client_id={item}
							cdm={(data) => this.setPI(data) }
						/>
						<FileUploader
							style={{width: '20px !important'}}
							id="picture"
							name="picture"
							ref={this.inputRef}
							handleChange={(event) => this.handleUploadFile(event)}
							classes={'fileUploader mb-1'}
							fileOrFiles
							types={['jpg', 'png', 'mp4', 'avi', 'mp3']}
							children={<ImageIcon className="inputMessageIcon mb-0" />}
						/>
					</div>
					<FormControl
						placeholder="Your answer"
						className="k-regular-15"
						value={this.state.newMessage}
						onChange={this.handleChangeCommentInput}
						onKeyDown={this.onKeyDown}
						style={{
							fontFamily: 'KohinoorRegular',
							border: '0',
							borderRadius: '0rem 25rem 25rem 0',
						}}
					/>
					<button
						type="submit"
						className="btn k-regular-15"
						onClick={this.submitMessage}>
						Send
					</button>
				</InputGroup>
			</div>
		);
	};

	// Step 3
	onKeyDown = (event) => {
		if (event.key === 'Enter' && this.state.newMessage.length) {
			this.submitMessage();
		}
		if (event.key === 'Enter' && this.state.openNewMessage === true) {
			// console.log(`Submit new user message`, this.state.newMessageUser);
		}
	};

	// Step 4 Push message and Send to socket
	submitMessage = (data) => {
    // If the message contains files (images/videos/audio...)
		if(this.state.inputList.length > 0) {
			const formData = new FormData();

			this.state.inputList.forEach((file, index) => {
				formData.append(`files[${index}]`, file)
			})

			formData.append('sender', this.props.user.id)
			formData.append('message', this.state.newMessage ? this.state.newMessage : '')
			formData.append('discussion', this.props.selectedDiscussion.discussion.id)

			WS.axiosPostMessageWithFile(
				formData,
				`${api.messages}`
			).then(
				(response) => {
					if(response.status == 200) {
						this.setState({ newMessage: '', images: [], inputList: [], listFile: [] });
			
						// this.setState({ newMessage: ''});
						// this.sort(this.props.discussions);
						const allMessages = [...this.props.messages]

						const lastMessage = {
							content: response.data.data.lastMessage.message,
							id: response.data.data.lastMessage.id,
							time: response.data.data.createdAt,
							files: response.data.data.lastMessage.files,
							isSentByCurrentUser: response.data.data.sender === this.props.user.id,
							img: defaultProfile
						}
						allMessages.push(lastMessage);
						this.props.showMessages(allMessages);
						this.scrollToLastMsg()
					}
				}
			)
			.catch(err => console.log('Error response message', err))
		}
		else {
			if (data) {
				this.setState({ newMessage: data });
				// this.scrollToLastMsg()
			}
			if (this.state.newMessage.trim().length) {
				const tab = [...this.props.messages];
				tab.push({
					id: tab.length,
					img: this.props.user.profil,
					time: this.state.date.getHours() + ':' + this.state.date.getMinutes(),
					content: this.state.newMessage,
					isSentByCurrentUser: true,
					images: this.state.images, 
				});
				this.sendMessageSocket();
				this.answerMessageSocket();
				this.props.showMessages(tab);
				this.setState({ newMessage: '', images: [] });
  
				// this.setState({ newMessage: ''});
				// this.sort(this.props.discussions);
  
				const discu = this.props.selectedDiscussion;
				// this.getAllDiscussions();
				getAllDicussions().then((data) => {
					this.props.showAllDiscussions(data.data.content);
					// this.props.setSelectedDiscussion(null);
					// this.props.showMessages([]);
					this.setState({ allDiscussions: data.data.content });
		
					this.getAllMessages(discu);
					this.setState({ openNewMessage: false });
					this.props.setSelectedDiscussion(discu);
				})
				.catch((_err) => { });
			}
			else{
				this.setState({newMessage: ''})
			}
		}
	};

	scrollToLastMsg() {
		let msgLB = document.getElementById('messageLastBottom')
		if (msgLB) {
			msgLB.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		}
	}

	// Step 5 New discussion
	sendMessageSocket = (isData) => {
		if (!this.props.selectedDiscussion && this.state.openNewMessage) {
			if (this.state.newReceivers.length < 1) {
				// toast.error('Veuillez choisir un utilisateur pour pouvoir discuter :)');
				return;
			}
			const receivers = this.state.newReceivers.map((u) => u.value);

			// rabbitmq.send(`/topic/new.message.${receivers[0]}`, {
			// 	message: isData? isData : this.state.newMessage,
			// 	receivers,
			// 	sender: this.props.user.id,
			// })
			SocketClient.send('/app/new.message', {
				message: isData? isData : this.state.newMessage,
				receivers,
				files: this.state.inputList,
				sender: this.props.user.id,
			})

			this.componentDidMount()

			// // Redirect after new message sent to socket
			this.openDialogMessage();
			this.setState({ openNewMessage: false });
			// this.getAllMessages(
			// 	this.props.discussions[this.props.discussions?.length - 1]
			// );
			this.props.setSelectedDiscussion(
				this.props.discussions[this.props.discussions?.length - 1]
			);
		}
	};
	
	updateDiscussion = () => {
		this.setState({ updateDiscussion: !this.state.updateDiscussion });
	};

	// Step 6 Existante discussion
	answerMessageSocket = () => {
		if (this.props.selectedDiscussion) {
			console.log('Sending in mobile');
			SocketClient.send(
				`/app/sendto.message`,
				{
					message: this.state.newMessage,
					discussion: this.props.selectedDiscussion.discussion?.id,
					sender: this.props.user.id,
				}
			);
		}
	};

	// Step 7 Afficher une discussion déjà existante
	openNewMessage = () => {
		this.setState({ openNewMessage: true });
	};

	handleSearchInputChange = (e) => {
		this.setState({ searchInput: e.target.value });
		if (e.target.value !== '') {
			searchUserByPseudo(e.target.value).then((response) => {
				this.setState({ resultSearchUser: response.data.data.content });
			});
		}
	};

	openMessageMobile = (id, data, userId) => {
		this.props.history.push({
			pathname: `/details-message/${id}`,
			data,
			origin: `newSimpleDiscussion`,
			userId: userId,
		});
	};

	openGroupMessageMobile = (id, data) => {
		this.props.history.push({
			pathname: `/details-message/${id}`,
			data,
			origin: `newGroupDiscussion`,
		});
	};
	
	// MOBILE and WEB search bar
	onRenderSearchForm = () => {
		return (
			<div className="inputSearch w-100">
				<InputGroup
					className="mb-3"
					id="select-mobile"
					style={{
						borderRadius: '125rem',
						border: 'solid 1px #C4C4C6',
						position: 'relative',
					}}>
					<button className="btn">
						<img src={ic_search} alt="" />
					</button>
					{this.state.openNewMessage ? (
						<>
							{/* Select New Discussion */}
							<div
								className="d-flex flex-row new-user-wapper"
								style={{ width: '85%' }}>
								<Select
									// placeholder="Nouvelle discussion"
									placeholder="News discussion"
									multi
									dropdownHandleRenderer={() => null}
									style={{
										fontFamily: 'KohinoorRegular',
										border: '0',
										borderRadius: '0rem 25rem 25rem 0',
										width: '100%',
									}}
									itemRenderer={this.customItemRenderer}
									onChange={(e) => this.setState({ newReceivers: e })}
									values={this.state.newReceivers}
									options={this.options()}
								/>
							</div>
						</>
					) : (
						// Select Existing Discussion
						<div
							className="d-flex flex-row new-user-wapper"
							style={{ width: '85%' }}>
							<Select
								placeholder="Search"
								multi
								dropdownHandleRenderer={() => null}
								style={{
									fontFamily: 'KohinoorRegular',
									border: '0',
									borderRadius: '0rem 25rem 25rem 0',
									width: '100%',
								}}
								itemRenderer={this.customItemRenderer}
								// Step 1 select newReceivers
								onChange={(e) => {
									this.openMessageMobile(e[0]?.value, e[0]?.pseudo);
								}}
								values={this.state.newReceivers}
								options={this.optionsSelectMobile()}
							/>
						</div>
					)}
				</InputGroup>
				{this.state.openNewMessage && this.state.newReceivers.length >= 1 && (
					// <button
					// 	type="button"
					// 	className="btn btn-sm btn-rounded k-regular-13 w-100"
					// 	style={{
					// 		fontFamily: 'KohinoorRegular',
					// 		border: 'solid 1px #F7CC2A',
					// 	}}
					// 	onClick={() => {
					// 		if (this.state.newReceivers.length === 1) {
					// 			// New Simple Discussion
					// 			this.openMessageMobile(
					// 				this.state.newReceivers[0]?.value,
					// 				this.state.newReceivers[0]?.pseudo,
					// 				this.state.newReceivers[0]?.value
					// 			);
					// 			console.log(`simple`, this.state.newReceivers.length);
					// 		} else if (this.state.newReceivers.length > 1) {
					// 			// New Group Discussion
					// 			this.openGroupMessageMobile(
					// 				this.state.newReceivers[0]?.value,
					// 				this.state.newReceivers
					// 			);
					// 			console.log(`double`, this.state.newReceivers.length);
					// 		}
					// 	}}>
					// 	Créer nouvelle discussion
					// </button>
					<button
						type="button"
						className="btn btn-sm btn-rounded k-regular-13 w-100"
						style={{
							fontFamily: 'KohinoorRegular',
							border: 'solid 1px #F7CC2A',
						}}
						onClick={() => {
							if (this.state.newReceivers.length === 1) {
								// New Simple Discussion
								this.openMessageMobile(
									this.state.newReceivers[0]?.value,
									this.state.newReceivers[0]?.pseudo,
									this.state.newReceivers[0]?.value
								);
							} else if (this.state.newReceivers.length > 1) {
								// New Group Discussion
								this.openGroupMessageMobile(
									this.state.newReceivers[0]?.value,
									this.state.newReceivers
								);
							}
						}}>
						Create new discussion
					</button>
				)}
			</div>
		);
	};
}

const mapStateToProps = (state) => {
	// console.log(`state msg`, state.chatReducers);
	return {
		messages: state.chatReducers.messages,
		newMessageCount: state.chatReducers.newMessages,
		discussions: state.chatReducers.discussions,
		discussion: state.chatReducers.selectedDiscussion,
		user: state.compteReducers.userConnected,
		selectedDiscussion: state.chatReducers.selectedDiscussion,
		updateDiscussion: state.chatReducers.updateDiscussion,
		isConnected: state.compteReducers.isConnected,
		refreshDiscussion: state.chatReducers.refreshDiscussion
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		showAllDiscussions: (data) => dispatch(showAllDiscussion(data)),
		onNewMessage: (data) => dispatch(newMessage(data)),
		showMessages: (data) => dispatch(showMessages(data)),
		setSelectedDiscussion: (data) => dispatch(setSelectedDiscussion(data)),
		setUpdateDiscussion: (data) => dispatch(setUpdateDiscussion(data)),
		setRefreshDiscussion: (data) => dispatch(refreshDiscussion(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesDetails);
