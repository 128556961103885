import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { api } from '../../res/constants';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(api.stripePromise);

const successMessage = () => {
	return (
		<div className="success-msg">
			<svg
				width="1em"
				height="1em"
				viewBox="0 0 16 16"
				className="bi bi-check2"
				fill="currentColor"
				xmlns="http://www.w3.org/2000/svg">
				<path
					fill-rule="evenodd"
					d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
				/>
			</svg>
			<div className="title">Payment Successful</div>
		</div>
	);
};

const cart = (product, sum) => {
	return (
		<React.Fragment>
			<h4 className="d-flex justify-content-between align-items-center mb-3">
				<span className="text-muted">Your basket</span>
				<span className="badge btn-yellow badge-pill">{product?.length} </span>
			</h4>
			<ul className="list-group mb-3">
				{product?.map((item, index) => (
					<li className="list-group-item d-flex justify-content-between lh-condensed">
						<div>
							<h6 className="my-0">{item.product.name}</h6>
							<small className="text-muted">{item.product.description}</small>
						</div>
						<span className="text-muted">$ {item.product.price}</span>
					</li>
				))}

				<li className="list-group-item d-flex justify-content-between">
					<span>Total</span>
					<strong>$ {sum}</strong>
				</li>
			</ul>
		</React.Fragment>
	);
};

function PaymentCard(props) {
	const [paymentCompleted, setPaymentCompleted] = useState(false);
	const [adresseClient, setAdresseClient] = useState([]);
	const [paniers, setPaniers] = useState([]);
	const [sum, setSum] = useState();
	const sumProducts = (data) => {
		let tab = [];
		let sumPrices = null;
		data?.map((item, ind) =>
			tab.push(parseInt(item.product.price * item.panier.quantity))
		);

		sumPrices = tab.reduce((accumulator, currentValue) => {
			return accumulator + currentValue;
		}, null);

		return sumPrices;
	};
	useEffect(() => {
		setAdresseClient(props.location?.state?.data);
		setPaniers(props.location?.state?.paniers);
		setSum(sumProducts(props.location?.state?.paniers));
	}, []);
	return (
		<div className="container pb-5" style={{paddingBottom: '80px'}}>
			<div className="py-5 text-center">
				<h4>Card information</h4>
			</div>

			<div className="row s-box">
				{paymentCompleted ? (
					successMessage()
				) : (
					<React.Fragment>
						<div className="col-md-5 order-md-2 mb-4">{cart(paniers, sum)}</div>
						<div className="col-md-7 order-md-1">
							<Elements stripe={stripePromise}>
								<CheckoutForm
									amount={sum}
									adresseClient={adresseClient}
									setPaymentCompleted={setPaymentCompleted}
								/>
							</Elements>
						</div>
					</React.Fragment>
				)}
			</div>
		</div>
	);
}

export default PaymentCard;
