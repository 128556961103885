import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { api } from "../../res/constants";
import { toast } from "react-toastify";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPaniersCounterReset } from "../../services/stores/actions/panier";
import { useState, useEffect } from "react"

export default function CheckoutFormPaypal(props) {
  const [ secretId, setSecretId ] = useState("");

  const dispatch = useDispatch();

  const dispatchPaniersCounterReset  = (data) => {
    return dispatch(setPaniersCounterReset(data));
  };

  useEffect(() => {
    setSecretId(props.secretId)   
  }, [props.secretId])

  const history = useHistory()
  return (
    <>
      <div>
        <div 
            className="d-flex w-100"
            style={{
                padding: '20px 20px 50px',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 'x-large',
                fontWeight: 'bold'
            }}
        >
            Finalize your payment with PayPal
        </div>
          <>
            <div>
              <PayPalScriptProvider
                options={{
                  clientId: api.PAYPAL_CLIENT_ID,
                }}
              >
              { props.privateRequest ? (
                <>
                  { secretId ? (
                    <>
                      <PayPalButtons
                        createOrder={(data, actions) => {
                          if(secretId) {
                            return secretId
                          }
                          // console.log('Hallo : ', secretId);
                        }}
                        onApprove={(data, actions) => {
                          return fetch(
                            `${api.baseUrl}${api.executePaymentPaypal}${data.orderID}`,
                            {
                              headers: {
                                Authorization: `Bearer ${localStorage.getItem("token")}`,
                              },
                            }
                          ).then(response => {
                            return response.json()
                          }).then(data => {
                            if(data.data === "COMPLETED") {
                              console.log('In paypal final ', props);
                              props.setDataUpdateParent()
                              dispatchPaniersCounterReset(data);
                              if(props.privateRequest) {
                                props.handleClose()
                                toast.success("Payment successful!", {
                                  position: "top-right",
                                  autoClose: 5000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "colored",
                                });
                              } else {
                                // history.push({
                                //   pathname: "/shopping",
                                // });
                                history.push({
                                  pathname: '/ValidationPaymentCard',
                                  state: {
                                    status: 'succeeded',
                                  },
                                });
                              }
                            } else {
                              // handle error if payment not success
                              toast.error('Error in payment process')
                            }
                          })
                        }}
                        onerror={(error) => {
                          console.log("ERRPR => ", error);
                        }}
                      />
                    </>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center">
                      Loading...
                    </div>
                  ) }
                </>
              ) : (
                <>
                  <PayPalButtons
                    createOrder={(data, actions) => {
                      return fetch(
                        `${api.baseUrl}/orders?adresse.adresse=${props.props.location.state.data.adresse}&adresse.codePostale=${props.props.location.state.data.zip_code}&adresse.nom=${props.props.location.state.data.lastname}&adresse.prenom=${props.props.location.state.data.firstName}&adresse.ville=${props.props.location.state.data.city}&adresse.pays=${props.props.location.state.data.country}&paymentType=${props.props.location.state.data.paymentType || props.props.location.state.data.methodPayment}`,
                        {
                          method: "POST",
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                          },
                        }
                      )
                        .then((response) => response.json())
                        .then((order) => {
                          const orderID = order.data;
                          return orderID;
                        })
                        .catch((err) => {
                          // Handle any error
                          console.log("ERROR_fetch", err);
                        });
                    }}
                    onApprove={(data, actions) => {
                      return fetch(
                        `${api.baseUrl}${api.executePaymentPaypal}${data.orderID}`,
                        {
                          headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                          },
                        }
                      ).then(response => {
                        return response.json()
                      }).then(data => {
                        if(data.data === "COMPLETED") {
                          console.log('In paypal final ', props);
                          toast.success("Payment successful!", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                          });
                          dispatchPaniersCounterReset(data);
                          if(props.privateRequest) {
                            props.handleClose()
                          } else {
                            history.push({
                              pathname: "/shopping",
                            });
                          }
                        } else {
                          // handle error if payment not success
                          toast.error('Error in payment process')
                        }
                      })
                    }}
                    onerror={(error) => {
                      console.log("ERRPR => ", error);
                    }}
                  />
                </>
              ) }
            </PayPalScriptProvider>
          </div>
          </>
        {/* ) : (
          <>
            <div>
              Loading...
            </div>
          </>
        )} */}
      </div>
    </>
  );
}