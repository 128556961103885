import { api } from '../../../res/constants';
import { LOGIN_SUCCESS, LOGOUT, DETAILS_USER_CURRENT } from './actionType';
import WS from './api';
import { toast } from 'react-toastify';
import ErrorNotification from '../../../Error/Error';
import axios from 'axios';

// GETTING CODE BY EMAIL
export const validateCodeByEmail = ({ email, subject }) => {
	const params = new URLSearchParams();
	params.append('channel', email);
	params.append('subject', subject);

	return new Promise((resolve, reject) => {
		WS.axiosPostURL(params, api.requestValidationCodeByEmail).then(
			(res) => {
				// console.log('RESPONSE ', res);
				resolve(true);
			},
			(error) => {
				// console.log('ERREUR DE VALIDATION PAR EMAIL ', error);
				reject(error);
			}
		);
	});
};

// GETTING CODE BY SMS
export const validateCodeBySms = ({ telephone, subject }) => {
	const params = new URLSearchParams();
	params.append('channel', telephone);
	params.append('subject', subject);

	return new Promise((resolve, reject) => {
		WS.axiosPostURL(params, api.requestValidationCodeBySms).then(
			(res) => {
				// console.log('RESPONSE ', res);
				resolve(true);
			},
			(error) => {
				// console.log('ERREUR DE VALIDATION PAR TELEPHONE ', error);
				reject(error);
			}
		);
	});
};

// CHECK IF CODE BY EMAIL OR PHONE IS VALID
export const checkValidationCode = ({ code, mode, subject }) => {
	const params = new URLSearchParams();
	params.append('channel', mode);
	params.append('subject', subject);
	params.append('code', code);

	return new Promise((resolve, reject) => {
		WS.axiosPostURL(params, api.requestCheckValidationCode).then(
			(res) => {
				// console.log('RESPONSE ', res);
				localStorage.setItem('dataCheck', JSON.stringify(res.data));
				resolve(true);
			},
			(error) => {
				// console.log('ERREUR DE VALIDATION DE CODE ', error);
				toast.error(`Code invalide`);
				reject(error);
			}
		);
	});
};

// SIGN  UP BY EMAIL OR PHONE NUMBER
export const inscriptionUsingEmailOrPhone = ({ data, picture }) => {
	const pseudo = localStorage.getItem('pseudo');
	const dataCheck = JSON.parse(localStorage.getItem('dataCheck'));
	const params = new FormData();
	// console.log('data check....: ', dataCheck);

	if (dataCheck.data.channelType === 'EMAIL') {
		params.append('email', dataCheck.data.channel);
	} else {
		params.append('telephone', dataCheck.data.channel);
	}
	params.append('pseudo', pseudo);
	params.append('description', data.description);
	params.append('birthyear', data.year);
	params.append('birthday', data.day);
	params.append('birthmonth', data.month);
	params.append('lastName', data.name);
	params.append('firstName', data.firstname);
	params.append('code', dataCheck.data.code);
	params.append('profil', picture);

	return new Promise((resolve, reject) => {
		WS.axiosPostFormData(params, api.inscriptions).then(
			(res) => {
				localStorage.setItem('dataUser', JSON.stringify(res));
				// console.log('RESPONSE AFTER SIGN UP ', res);
				resolve(true);
			},
			(error) => {
				// console.log(`error compte =`, error.response);
				ErrorNotification(error.response.data.data);
				reject(error);
			}
		);
	});
};

// RESET PASSWORD USING CODE
export const resetpasswordWithCode = (channel, password, code) => {
	const formData = new FormData();
	formData.append('channel', channel);
	formData.append('password', password);
	formData.append('code', code);

	return new Promise((resolve, reject) => {
		WS.axiosPostFormData(formData, api.resetpasswordWithCode).then(
			(res) => {
				// console.log('RESPONSE AFTER RESET PASSWORD ', res);
				localStorage.removeItem('pseudo');
				localStorage.removeItem('dataCheck');
				// toast.success(`Mot de passe modifié avec succès`);
				resolve(true);
			},
			(error) => {
				// console.log('ERREUR RESET PASSWORD ', error);
				toast.error(`Erreur lors de la réinitialisation du mot de passe`);
				reject(error);
			}
		);
	});
};

// CONFIRM PASSWORD AFTER SIGN UP USING USER ID
export const ResetPasswordAfterInscription = ({ data, id }) => {
	const params = new URLSearchParams();
	const dataCheck = JSON.parse(localStorage.getItem('dataCheck'));
	params.append('user', id);
	params.append('password', data.password);
	params.append('pseudo', data.pseudo);
	params.append('code', dataCheck.data.code)
	// console.log('object ', data);

	return new Promise((resolve, reject) => {
		WS.axiosPostURL(params, api.resetPasswordInscription).then(
			(res) => {
				// console.log('RESPONSE ', res);
				// toast.success(`Inscription terminé avec succès`);
				resolve(true);
			},
			(error) => {
				// console.log('ERREUR DE VALIDATION DE CODE ', error);
				toast.error(`Erreur lors de la validation du mot de passe`);
				reject(error);
			}
		);
	});
};

// LOG IN BY EMAIL OR PHONE OR USERNAME
export const login = (username, password) => (dispatch) => {
	// console.log('username: ', username);
	const formData = new FormData();
	formData.append('username', username);
	formData.append('password', password);

	return new Promise((resolve, reject) => {
		WS.axiosPostFormData(formData, api.login).then(
			(res) => {
				dispatch({
					type: LOGIN_SUCCESS,
					data: res.data.data,
				});
				resolve(res.data.data);
			},
			(error) => {
				// console.log('ERROR LOG IN : ', error);
				reject(error);
			}
		);
	});
};

// LOGIN VIA APPLE
export const loginByApple = (accessToken) => (dispatch) => {
	const email = JSON.parse(atob(accessToken.split('.')[1])).email;
	const formData = new FormData();
	formData.append('apple_id', accessToken);
	formData.append('email', email);

	return new Promise((resolve, reject) => {
		WS.axiosPostFormData(formData, '/users/login-apple').then(
			(res) => {
				dispatch({
					type: LOGIN_SUCCESS,
					data: res.data.data,
				});
				resolve(res.data.data);
			},
			(error) => {
				// console.log('ERROR LOGIN FACEBOOK : ', error);
				reject(error);
			}
		);
	});
};
// SIGN UP BY APPLE
export const signUpByApple = (accessToken) => {
	// const id_token = response.authorization.id_token;
	const email = JSON.parse(atob(accessToken.split('.')[1])).email;
	const formData = new FormData();
	formData.append('apple_id', accessToken);
	formData.append('email', email);

	return new Promise((resolve, reject) => {
		WS.axiosPostFormData(formData, '/inscriptions/signup-apple').then(
			(res) => {
				// console.log('RES SIGN UP ',res)
				resolve(res);
			},
			(error) => {
				// console.log('ERROR SIGN UP FACEBOOK : ', error);
				ErrorNotification(error.response.data.data);
				reject(error);
			}
		);
	});
};

// SIGN UP BY FACEBOOK
export const signUpByFacebook = (accessToken) => {
	const formData = new FormData();
	formData.append('access_token', accessToken);

	return new Promise((resolve, reject) => {
		WS.axiosPostFormData(formData, api.inscriptionFacebook).then(
			(res) => {
				// console.log('RES SIGN UP ',res)
				resolve(res);
			},
			(error) => {
				// console.log('ERROR SIGN UP FACEBOOK : ', error);
				ErrorNotification(error.response.data.data);
				reject(error);
			}
		);
	});
};

// LOGIN VIA FACEBOOK
export const loginByFacebook = (accessToken) => (dispatch) => {
	const formData = new FormData();
	formData.append('access_token', accessToken);

	return new Promise((resolve, reject) => {
		WS.axiosPostFormData(formData, api.loginFacebook).then(
			(res) => {
				dispatch({
					type: LOGIN_SUCCESS,
					data: res.data.data,
				});
				resolve(res.data.data);
			},
			(error) => {
				// console.log('ERROR LOGIN FACEBOOK : ', error);
				reject(error);
			}
		);
	});
};

// SIGN UP BY INSTAGRAM
export const signUpByInstagram = (codex, url) => {
	const formData = new URLSearchParams();
	formData.append('code', codex);
	formData.append('redirect_url', url);
	return new Promise((resolve, reject) => {
		WS.axiosPostURL(formData, api.inscriptionInstagram).then(
			(res) => {
				// console.log('RES SIGN UP INSTAGRAM : ', res);
				resolve(res);
			},
			(error) => {
				// console.log('ERROR SIGN UP INSTAGRAM : ', error);
				reject(error);
			}
		);
	});
};

export const getTokenInstagram = (token, endpoint) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'POST',
			url: `https://api.instagram.com/oauth/authorize?client_id=566704898050220&client_secret=aae14b8af6f48ce31fa1cdaab56a6f03&redirect_uri=https://site.mybeedoo.com/&scope=user_profile,user_media&response_type=code`,
		}).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				// console.log('ERROR CHECK TOKEN ', error);
				reject(error);
			}
		);
	});
};

// GET FULL PROFILE Signup by Google GOOGLE DATA
export const getFullProfile = (token, endpoint) => {
	return new Promise((resolve, reject) => {
		axios({
			method: 'GET',
			url: endpoint,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		}).then(
			(res) => {
				// console.log(`check user token=`, res);
				resolve(res);
			},
			(error) => {
				// console.log('ERROR CHECK TOKEN ', error);
				reject(error);
			}
		);
	});
};

// Signup by Google
export const signUpByGoogle = (
	accessToken,
	google_id,
	email,
	family_name,
	given_name,
	gender,
	picture
) => {
	const formData = new FormData();

	formData.append('access_token', accessToken);
	formData.append('google_id', google_id);
	formData.append('email', email);
	formData.append('family_name', family_name);
	formData.append('given_name', given_name);
	formData.append('gender', gender);
	formData.append('picture', picture);

	return new Promise((resolve, reject) => {
		WS.axiosPostFormData(formData, api.inscriptionGoogle).then(
			(res) => {
				// console.log('RES SIGN UP ',res)
				resolve(res);
			},
			(error) => {
				// console.log('ERROR SIGN UP GOOGLE : ', error);
				ErrorNotification(error.response);
				reject(error);
			}
		);
	});
};

// LOGIN VIA GOOGLE
export const loginByGoogle = (google_id, email) => (dispatch) => {
	const formData = new FormData();
	formData.append('google_id', google_id);
	formData.append('email', email);

	return new Promise((resolve, reject) => {
		WS.axiosPostFormData(formData, api.loginGoogle).then(
			(res) => {
				dispatch({
					type: LOGIN_SUCCESS,
					data: res.data.data,
				});
				resolve(res.data.data);
			},
			(error) => {
				// console.log('ERROR LOGIN GOOGLE : ', error);
				reject(error);
			}
		);
	});
};

// Get All User
export const searchUser = (data, pagination = 10) => {
	return new Promise((resolve, reject) => {
		const token = localStorage.getItem('token');
		if (token) {
			WS.axiosGetWithParamsSecure(
				{ pseudo: data },			
				`${api.myFriends}`
			).then(
				(res) => {
					// console.log('GET ALL USERS : ', res);
					resolve(res);
				},
				(error) => {
					// console.log('ERROR GET ALL USERS : ', error);
					reject(error);
				}
			);
		} else {
			WS.axiosGetWithParams({
				endpoint: `${api.users}?pagination.par_page=${pagination}`,
				params: data,
			}).then(
				(res) => {
					// console.log('GET ALL USERS : ', res);
					resolve(res);
				},
				(error) => {
					// console.log('ERROR GET ALL USERS : ', error);
					reject(error);
				}
			);
		}
	});
};

// Get All User
export const searchUserContent = (data, pagination = 10) => {
	return new Promise((resolve, reject) => {
		const token = localStorage.getItem('token');
		if (token) {
			WS.axiosGetWithParamsSecure(
				{ pseudo: data },			
				`${api.contents}?pagination.par_page=${pagination}`
			).then(
				(res) => {
					// console.log('GET ALL USERS : ', res);
					resolve(res);
				},
				(error) => {
					console.log('ERROR GET ALL USERS : ', error);
					reject(error);
				}
			);
		} else {
			WS.axiosGetWithParams({
				endpoint: `${api.contents}?pagination.par_page=${pagination}`,
				params: data,
			}).then(
				(res) => {
					// console.log('GET ALL USERS : ', res);
					resolve(res);
				},
				(error) => {
					console.log('ERROR GET ALL USERS : ', error);
					reject(error);
				}
			);
		}
	});
};

export const searchUserByPseudoPublic = (data) => {
	return new Promise((resolve, reject) => {
		// WS.axiosGetWithParams({endpoint: `${api.userByPseudo}/${data}?`}).then(
		WS.axiosGetWithParams({endpoint: `${api.users}?pseudo=${data}&status=ACTIVE`}).then(
			(res) => {
				console.log("GET USER BY PSEUDO: ", res);
				resolve(res);
			},
			(error) => {
				console.log('ERROR GET ALL USERS : ', error);
				reject(error);
			}
		);
	});
};

export const searchAllPublic = (data) => {
	return new Promise((resolve, reject) => {
		WS.axiosGetWithParams({endpoint: `${api.searchPublic}?key=${data}`}).then(
			(res) => {
				console.log("GET USER BY PSEUDO: ", res);
				resolve(res);
			},
			(error) => {
				console.log('ERROR GET ALL USERS : ', error);
				reject(error);
			}
		);
	});
};

export const searchUserByPseudo = (data, isAll) => {
	return new Promise((resolve, reject) => {
		if (localStorage.getItem('token')) {
			if (!isAll) {
				WS.axiosGetWithParamsSecure(
					data,
					`${api.usersForConnected}?fields=ID,FIRST_NAME,LAST_NAME,PROFIL,PSEUDO,PROFIL,GENDER,FRIENDS,USER_BLOCKED&status=ACTIVED, ACTIVE, VERIFIED&pseudo=${data}`
				).then(
					(res) => {
						// console.log('GET ALL USERS : ', res);
						resolve(res);
					},
					(error) => {
						console.log('ERROR GET ALL USERS : ', error);
						reject(error);
					}
				);
			} else {
				WS.axiosGetWithParamsSecure(data, `${api.users}?pseudo=${data}`).then(
					(res) => {
						// console.log('GET ALL USERS : ', res);
						resolve(res);
					},
					(error) => {
						console.log('ERROR GET ALL USERS : ', error);
						reject(error);
					}
				);
			}
		} else {
			WS.axiosGetWithParams({
				endpoint: api.users,
				params: { pseudo: data },
			}).then(
				(res) => {
					// console.log("GET ALL USERS : ", res);
					resolve(res);
				},
				(error) => {
					console.log('ERROR GET ALL USERS : ', error);
					reject(error);
				}
			);
		}
	});
};

export const myInfos = () => {
	return new Promise((resolve, reject) => {
		if (localStorage.getItem('token')) {
			WS.axiosGetWithToken({endpoint: `${api.myUsersData}`}).then(
				(res) => {resolve(res)},
				(error) => {reject(error)}
			);
		}
	});
};

export const searchDiscussionByUser = (data) => {
	return new Promise((resolve, reject) => {
		WS.axiosGetWithToken({
			endpoint: `/discussions/user/${data}`,
		}).then(
			(res) => {
				console.log('Discussion with user = : ', res);
				resolve(res);
			},
			(error) => {
				console.log('ERROR discussion with user : ', error);
				reject(error);
			}
		);
	});
}
export const checkRequestFriend = (data, token) => {
	return new Promise((resolve, reject) => {
		WS.axiosGetWithToken({
			endpoint: api.usersForConnected,
		}).then(
			(res) => {
				console.log('check friend response = : ', res);
				resolve(res);
			},
			(error) => {
				console.log('ERROR check friend response : ', error);
				reject(error);
			}
		);
	});
};
export const sendNewRequestFriend = (data) => {
	return new Promise((resolve, reject) => {
		WS.axiosPostWithToken(`${api.requestFriend + '/' + data}`).then(
			(res) => {
				console.log('Resssss ', res);
				resolve(res);
			},
			(error) => {
				console.log('ERROR request friend response : ', error);
				reject(error);
			}
		);
	});
};

export const friendRequestValidate = (data) => {
	return new Promise((resolve, reject) => {
		WS.axiosPutWithToken(
			`${api.requestFriend + '/' + data + '/validate'}`
		).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERROR request friend response : ', error);
				reject(error);
			}
		);
	});
};

export const friendRequestRefuse = (data) => {
	return new Promise((resolve, reject) => {
		WS.axiosPutWithToken(`${api.requestFriend + '/' + data + '/refuse'}`).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERROR request friend response : ', error);
				reject(error);
			}
		);
	});
};

export const cancelNewRequestFriend = (data) => {
	return new Promise((resolve, reject) => {
		WS.axiosDeleteWithToken(`${api.requestFriend}/${data}`).then(
			(res) => {
				console.log('Ressss : ', res);
				resolve(res);
			},
			(error) => {
				console.log('ERROR CANCEL friend response : ', error);
				reject(error);
			}
		);
	});
};
export const getAllNewRequestFriend = (
	labelStatus,
	labelRequest,
	senderId,
	labelReceiver,
	receiverId
) => {
	var request = '';
	var request2 = '';
	var status = '';
	if (labelStatus) {
		status = `?status=${labelStatus}`;
	}
	if (labelRequest) {
		request = `&${labelRequest}=${senderId}`;
	}
	if (labelReceiver) {
		request2 = `&${labelReceiver}=${receiverId}`;
	}

	return new Promise((resolve, reject) => {
		WS.axiosGetWithToken({
			endpoint: `${api.requestFriend}${status}${request}${request2}`,
		}).then(
			(res) => {
				// console.log('get all request friend = : ', res);
				resolve(res);
			},
			(error) => {
				console.log('ERROR Get all request friend : ', error);
				reject(error);
			}
		);
	});
};

// Friens suggestion
export const friendSuggestion = (userId) => {
	return new Promise((resolve, reject) => {
		if (localStorage.getItem('token')) {
			WS.axiosGetWithToken({
				endpoint: `${api.friendSuggestions+'?userId='+userId}`
			}).then(
				(res) => {
					resolve(res);
				},
				(error) => {
					reject(error);
				}
			);
		} else {
			WS.axiosGetWithParams({endpoint: api.friendSuggestions, params: userId}).then(
				(res) => {
					resolve(res);
				},
				(error) => {
					reject(error);
				}
			);
		}

	})
}

export const getAllRequestFriend = () => {
	return new Promise((resolve, reject) => {
		WS.axiosGetWithToken({
			endpoint: `${api.requestFriend}`,
		}).then(
			(res) => {
				// console.log('get all request friend = : ', res);
				resolve(res);
			},
			(error) => {
				console.log('ERROR Get all request friend : ', error);
				reject(error);
			}
		);
	});
};
export const getUserForConnected = (token) => {
	return new Promise((resolve, reject) => {
		WS.axiosGetWithToken({
			endpoint: api.usersForConnected,
		}).then(
			(res) => {
				console.log('getUserForConnected = : ', res);
				resolve(res);
			},
			(error) => {
				console.log('ERROR Gget all request friend : ', error);
				reject(error);
			}
		);
	});
};
export const userPostBlocker = (data) => {
	return new Promise((resolve, reject) => {
		WS.axiosPostWithToken(`${api.users}/blocked/${data}`).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERROR BLOCKED user response : ', error);
				reject(error);
			}
		);
	});
};

export const userDeleteBlocker = (data) => {
	return new Promise((resolve, reject) => {
		WS.axiosDeleteWithToken(`${api.users}/blocked/${data}`).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERROR DELETE user response : ', error);
				reject(error);
			}
		);
	});
};

export const userPostBloquerInDiscussion = (data) => {
	return new Promise((resolve, reject) => {
		WS.axiosPostWithToken(`${api.users}/blockedfromdiscussion/${data}`).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERROR BLOCKED user response ', error);
				reject(error);
			}
		)
	})
}

export const userDeleteBlockerInDiscussion = (data) => {
	return new Promise((resolve, reject) => {
		WS.axiosDeleteWithToken(`${api.users}/blockedfromdiscussion/${data}`).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERROR DELETE user response : ', error);
				reject(error);
			}
		);
	});
};
export const userGetListBlocker = () => {
	return new Promise((resolve, reject) => {
		WS.axiosGetWithToken({
			endpoint: `${api.users}/blocked`,
		}).then(
			(res) => {
				resolve(res.data);
			},
			(error) => {
				console.log('ERROR GET LIST BLOCKED user response : ', error);
				reject(error);
			}
		);
	});
};

export const updateUserAccount = ({ description, isPublic, password, telephone, profil, translateLang }) => {
	const formData = new FormData();
	// formData.append('description', description);
	// formData.append('isPublic', isPublic);
	if (description) {
		formData.append('description', description);
	}
	if (isPublic) {
		formData.append('isPublic', isPublic);
	}
	if (password) {
		formData.append('password', password);
	}
	if (telephone) {
		formData.append('telephone', telephone);
	}
	if (profil) {
		formData.append('profil', profil);
	}
	if (translateLang) {
		formData.append('translateLang.alpha2', translateLang.alpha2)
		formData.append('translateLang.alpha3', translateLang.alpha3)
		formData.append('translateLang.name', translateLang.name)
	}
	if (description || isPublic || password || telephone || profil || translateLang) {
		return new Promise((resolve, reject) => {
			// WS.axiosPutFormUrlEncoded(formData, `${api.users}/mon-compte`).then(
			WS.axiosPostFormDataWithToken(formData, `${api.users}/mon-compte`).then(
				(res) => {
					resolve(res);
				},
				(error) => {
					console.log('ERREUR modification compte', error);
					toast.error(`Modification non envoyée`);
					reject(error);
				}
			);
		});
	}
};

export const userAccountValidate = (props) => {
	let formData = new FormData();
	if(props.picturePasseportFile) {
		formData.set(`attachments[0]`, props.picturePasseportFile);
	}
	if(props.picturePasseportVersoFile) {
		formData.set(`attachments[1]`, props.picturePasseportVersoFile);
	}
	if(props.pictureSelfieFile) {
		if(props.picturePasseportVersoFile) {
			formData.set(`attachments[2]`, props.pictureSelfieFile);
		} else {
			formData.set(`attachments[1]`, props.pictureSelfieFile);
		}
	}

	return new Promise((resolve, reject) => {
		WS.axiosSecurePost(formData, `${api.usersAttachments}`).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERREUR modification compte', error);
				toast.error(`Modification non envoyée`);
				reject(error);
			}
		);
	});
};
// ---------------------------------------------- *** -----------------------------------------

// ACTION LOGIN SUCCESS
export const loginSuccess = (data) => {
	return {
		type: LOGIN_SUCCESS,
		data,
	};
};

// ACTION LOGOUT
export const logout = (data) => {
	return {
		type: LOGOUT,
		data,
	};
};

// ACTION GET DETAILS USER ID
export const setDetailsUserId = (data) => {
	return {
		type: DETAILS_USER_CURRENT,
		data,
	};
};
