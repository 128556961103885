import { api } from '../../../res/constants';
import WS from './api';
import { toast } from 'react-toastify';
import { COMMENT_RECEIVED, CONTENT_RECEIVED, CONTENT_SIGNAL_ID } from './actionType';

export const createContent = (params, addProduct, id) => {
        const fd = new FormData();
        // Creation Content with product
        if (!addProduct) {
            fd.append('visibility', params.visibility);
            fd.append('isCommented', !params.isCommented);
            fd.append('legend', params.legend);
            fd.append('category', params.category);
            fd.append('isEphemere', params.isEphemere);
            if (params.sell) {
                for (let i = 0; i < params.sell.length; i++) {
                    fd.append(`sell.products[${i}].name`, params.sell[i].name);
                    fd.append(`sell.products[${i}].price`, params.sell[i].price);
                    fd.append(
                        `sell.products[${i}].description`,
                        params.sell[i].description
                    );
                    fd.append(`sell.products[${i}].quantity`, params.sell[i].quantity);
                    fd.append(`products[${i}].shippingCountry`, params.sell[i].shippingCountry);
                    fd.append(`products[${i}].shippedMin`, params.sell[i].shippedMin);
                    fd.append(`products[${i}].shippedMax`, params.sell[i].shippedMax);
                    for (let j = 0; j < params.sell[i].images.length; j++) {
                        fd.append(
                            `sell.products[${i}].images[${j}]`,
                            params.sell[i].images[j]
                        );
                    }
                }
            }
            for (let i = 0; i < params.illustrations.length; i++) {
                fd.append(`illustrations[${i}]`, params.illustrations[i]);
            }

            if (params.groups) {
                for (let i = 0; i < params.groups.length; i++) {
                    fd.append(`groups[${i}]`, params.groups[i]);
                }
            }
        }
        // Adding Product in existing content
        else {
            for (let i = 0; i < params.sell.length; i++) {
                fd.append(`products[${i}].name`, params.sell[i].name);
                fd.append(`products[${i}].price`, params.sell[i].price);
                fd.append(`products[${i}].description`, params.sell[i].description);
                fd.append(`products[${i}].quantity`, params.sell[i].quantity);
                fd.append(`products[${i}].shippingCountry`, params.sell[i].shippingCountry);
                fd.append(`products[${i}].shippedMin`, params.sell[i].shippedMin);
                fd.append(`products[${i}].shippedMax`, params.sell[i].shippedMax);
                for (let j = 0; j < params.sell[i].images.length; j++) {
                    fd.append(`products[${i}].images[${j}]`, params.sell[i].images[j]);
                }
            }
        }

        return new Promise((resolve, reject) => {
                    WS.axiosSecurePost(
                            fd,
                            `${addProduct ? `${api.contents}/${id}/add-product` : api.contents}`
		).then(
			(res) => {
				// toast.success(`Contenu publié avec succès`);
				// toast.success();
				resolve(res.data);
			},
			(error) => {
				toast.error(
					`${
						params.sell
							? `Erreur Ajout produit`
							: 'Erreur : contenu non sauvegardé'
					}`
				);
				reject();
			}
		);
	});
};

export const updateContent = (data) => {
	const fd = new FormData();
	console.log('DAta for formdata : ', data);

	fd.append('id', data.id);
	fd.append('deletedIllustrations', data.deletedIllustrations);
	fd.append('deletedTags', data.deletedTags);
	fd.append('duration', data.duration);
	fd.append('groups', data.groups);
	for (let i = 0; i < data.illustrations.length; i++) {
		fd.append('illustrations', data.illustrations[i]);
	}
	fd.append('isCommented', data.isCommented);
	fd.append('isEphemere', data.isEphemere);
	fd.append('legend', data.legend);
	fd.append('visibility', data.visibility);

	return new Promise((resolve, reject) => 
		WS.axiosSecurePost(fd, `${api.contents}/${data.id}/update`)
			.then(res => {
				console.log('Response update content ', res);
				resolve(res);
			},
			(err) => {
				toast.err('ERR update content')
				reject(err);
			})
	)
}

export const findContent = (params) => {
	const urlParams = new URLSearchParams();
	for (const key in params) {
		if (Object.hasOwnProperty.call(params, key)) {
			urlParams.append(key, params[key]);
		}
	}

	return WS.axiosGet(api.contents + '?' + urlParams.toString()).then(
		(res) => res.data
	);
};

export const findMyContent = (params) => {
	const urlParams = new URLSearchParams();
	for (const key in params) {
		if (Object.hasOwnProperty.call(params, key)) {
			urlParams.append(key, params[key]);
		}
	}

	return WS.axiosSecureGet(api.myContents + '?' + urlParams.toString()).then(
		(res) => res.data
	);
};

export const findProducts = (params) => {
	const urlParams = new URLSearchParams();
	for (const key in params) {
		if (Object.hasOwnProperty.call(params, key)) {
			urlParams.append(key, params[key]);
		}
	}

	return WS.axiosGet(`/public/products?${urlParams.toString()}`).then(
		(res) => res.data
	);
};

export const getContent = () => {
	const url = api.contents;

	return new Promise((resolve, reject) => {
		WS.axiosGet(url).then(
			(response) => {
				// console.log("CONTENTS DATA= ", response);
				resolve(response.data.data);
			},
			(error) => {
				console.log('ERROR ', error);
				reject(error);
			}
		);
	});
};

export const getContentById = ({ id }) => {
	const url = api.contents;

	return new Promise((resolve, reject) => {
		WS.axiosGet(url + '/' + id).then(
			(response) => {
				// console.log("CONTENTS DATA= ", response);
				resolve(response.data.data);
			},
			(error) => {
				console.log('ERROR ', error);
				reject(error);
			}
		);
	});
};
export const getProductByContentId = ({ id }) => {
	const url = api.contents;

	return new Promise((resolve, reject) => {
		WS.axiosGet(url + '/' + id + '/products').then(
			(response) => {
				// console.log("CONTENTS DATA= ", response);
				resolve(response.data.data);
			},
			(error) => {
				console.log('ERROR ', error);
				reject(error);
			}
		);
	});
};

export const getProductById = ({ id }) => {
	const url = api.products;

	return new Promise((resolve, reject) => {
		WS.axiosSecureGet(`${url}/${id}`).then(
			(response) => {
				// console.log("CONTENTS DATA= ", response);
				resolve(response.data.data);
			},
			(error) => {
				console.log('ERROR ', error);
				reject(error);
			}
		);
	});
};

export const likeSend = ({ id, isLike }) => {
	const url = api.contents;
	var url_like = 'like';
	if (!isLike) {
		url_like = 'dislike';
	}
	return new Promise((resolve, reject) => {
		WS.axiosSecureGet(
			`${url}/${id}/${url_like}`,
			localStorage.getItem('token')
		).then(
			(response) => {
				// console.log("CONTENTS DATA= ", response);
				resolve(response.data.data);
			},
			(error) => {
				console.log('ERROR ', error);
				reject(error);
			}
		);
	});
};

export const getContentByLike = () => {
	const url = api.myLikes;

	return new Promise((resolve, reject) => {
		WS.axiosSecureGet(`${url}`).then(
			(response) => {
				// console.log('CONTENTS LIKES= ', response);
				resolve(response.data.data);
			},
			(error) => {
				console.log('ERROR ', error);
				reject(error);
			}
		);
	});
};

export const getContentWithLike = (id) => {
	const url = api.contentsWithLikes;

	return new Promise((resolve, reject) => {
		WS.axiosSecureGet(`${url}/${id}`).then(
			(response) => {
				resolve(response.data.data);
			},
			(error) => {
				console.log('ERROR ', error);
				reject(error);
			}
		);
	});
};
export const contentUpdate = ({
	id,
	visibility,
	isCommented,
	legend,
	isEphemere,
}) => {
	const formData = new FormData();
	formData.append('isCommented', isCommented);
	formData.append('legend', legend);
	if (visibility) {
		formData.append('visibility', visibility);
	}
	if (isEphemere) {
		formData.append('isEphemere', isEphemere);
	}

	return new Promise((resolve, reject) => {
		WS.axiosPostFormDataWithToken(formData, api.contents + '/' + id + '/update').then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERREUR Modification ', error);
				toast.error(`Modification non envoyée`);
				reject(error);
			}
		);
	});
};

export const contentDelete = (data) => {
	return new Promise((resolve, reject) => {
		WS.axiosDeleteWithToken(`${api.contents}/${data}`).then(
			(res) => {
				resolve(res);
			},
			(error) => {
				console.log('ERROR CANCEL friend response : ', error);
				reject(error);
			}
		);
	});
};

export const deleteContentRequest = (data) =>
	new Promise ((resolve, reject) => {
		WS.axiosPostWithToken(`${api.contents}/${data}/requesting-delete`)
			.then((res) =>{
				console.log('response', res);
				resolve(res);

			},
			(error)=>{
				console.log("ERROR CANCEL friend response:", error);
				reject(error);
			}
			)
	})
export const commentSend = ({ id, comment, parentId }) => {
	const fd = new FormData();
	fd.append('contentId', id);
	if (parentId) {
		fd.append('parentId', parentId);
	}
	fd.append('comment', comment);

	return new Promise((resolve, reject) => {
		WS.axiosSecurePost(fd, api.comments).then(
			(res) => {
				// toast.success(`Contenu commenté`);
				resolve(res.data);
			},
			(error) => {
				toast.error(`Erreur : contenu non commenté`);
				reject(error);
			}
		);
	});
};
export const commentGetList = (id) => {
	const url = api.comments;
	return new Promise((resolve, reject) => {
		WS.axiosSecureGet(`${url}/${id}`).then(
			(response) => {
				resolve(response.data);
			},
			(error) => {
				console.log('ERROR ', error);
				reject(error);
			}
		);
	});
};
export const commentGetWithLike = (id) => {
	return new Promise((resolve, reject) => {
		WS.axiosSecureGet(`${api.comments}/${id}/with-like`).then(
			(response) => {
				resolve(response.data);
			},
			(error) => {
				console.log('ERROR ', error);
				reject(error);
			}
		);
	});
};
export const commentGetLike = (id, isLike) => {
	const url = api.comments;
	var url_like = 'like';
	if (!isLike) {
		url_like = 'dislike';
	}
	return new Promise((resolve, reject) => {
		WS.axiosSecureGet(`${url}/${id}/${url_like}`).then(
			(response) => {
				resolve(response.data);
			},
			(error) => {
				console.log('ERROR ', error);
				reject(error);
			}
		);
	});
};

export const contentPostSignaler = ({
	email,
	reason,
	contentId,
	userId,
	commentId,
}) => {
	const fd = new FormData();
	fd.append('reason', reason);
	fd.append('reasons', reason);
	fd.append('email', email);
	if (contentId) {
		fd.append('contentId', contentId);
	}
	if (commentId) {
		fd.append('commentId', commentId);
	}
	if (userId) {
		fd.append('userId', userId);
	}

	return new Promise((resolve, reject) => {
		WS.axiosSecurePost(fd, api.signalement).then(
			(response) => {
				resolve(response);
			},
			(error) => {
				reject(error);
			}
		);
	});
};
export const shareViewContentGet = (id, isShare) => {
	var url = 'share';
	if (!isShare) {
		url = 'view';
	}
	return new Promise((resolve, reject) => {
		WS.axiosSecureGet(`${api.share_view_contents}/${url}/${id}`).then(
			(response) => {
				// toast.success(`Contenu ${isShare ? 'partagé' : 'Vue'}`);
				resolve(response.data);
			},
			(error) => {
				toast.error(`Erreur ${isShare ? 'partage' : 'Vue'}`);
				reject(error);
			}
		);
	});
};

export const getUserById = (id) => {
	return new Promise((resolve, reject) => {
		WS.axiosSecureGet(`${api.users}/${id}`).then(
			(response) => {
				resolve(response.data)
			},
			(error) => {
				reject(error)
			}
		)
	})
}


// ACTION MESSAGE RECEIVED
export const newContent = (data) => {
	return {
		type: CONTENT_RECEIVED,
		data,
	};
};
export const setContentSignalId = (data) => {
	return {
		type: CONTENT_SIGNAL_ID,
		data,
	};
};

// ACTION COMMENT RECEIVED
export const newComment = (data) => {
	return {
		type: COMMENT_RECEIVED,
		data
	};
};