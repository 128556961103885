import React, { useState, useEffect } from 'react';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { Form, Modal } from 'react-bootstrap';
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	Elements,
} from '@stripe/react-stripe-js';

import CheckoutForm from '../../components/Payment/CheckoutForm';
import { stripePaymentMethodHandler } from '../../services/stores/actions/orders';
import { loadStripe } from '@stripe/stripe-js';
import { api } from '../../res/constants';
let stripePromise;
const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			lineHeight: '27px',
			color: '#212529',
			fontSize: '1.1rem',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
};

export function ModalStripe(props) {
	const [show, setShow] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	const [error, setShowError] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const stripe = getStripe();
	let elements;
	
	const handleSubmit = async (event) => {
		// const elements = useElements();
		event.preventDefault();

		if (!stripe) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}
		const paymentMethodObj = {
			type: 'card',
			card: '4242 4242 4242 4242',
			// card: elements.getElement(CardNumberElement),
			billing_details: {
				name: 'pseudo',
				email: 'aaa@aaa.aaa',
			},
		};
		const paymentMethodResult = await stripe.createPaymentMethod(
			paymentMethodObj
		);

		stripePaymentMethodHandler(
			{
				result: paymentMethodResult,
				amount: '1000',
				client: 'adresseClient',
			},
			handleResponse
		).then((response) => {
			stripe
				.confirmCardPayment(response.data, {
					payment_method: paymentMethodObj,
				})
				.then(
					(response) => {
						if (response.error) {
						} else {
						}
						// history.push({
						// 	pathname: '/ValidationPaymentCard',
						// 	state: {
						// 		status: response.error
						// 			? 'Error'
						// 			: response?.paymentIntent?.status,
						// 	},
						// });
					},
					(error) => {
						// console.log(`Error`, error);
					}
				);
		});


		// const stripe = await getStripe();
		// stripe.confirmCardPayment('pi_3MzFQWDmyHx2lsux1qeSePT8_secret_H0yqtCT9YIejbebsHVRozc42L', {
		// 	payment_method: 'paymentMethodObj',
		// })
		// .then(
		// 	(response) => {
		// 		if (response.error) {
		// 		} else {
		// 		}
		// 		// history.push({
		// 		// 	pathname: '/ValidationPaymentCard',
		// 		// 	state: {
		// 		// 		status: response.error
		// 		// 			? 'Error'
		// 		// 			: response?.paymentIntent?.status,
		// 		// 	},
		// 		// });
		// 	},
		// 	(error) => {
		// 		console.log(`Error`, error);
		// 	}
		// );
	}

	const handleResponse = (response) => {
		// setLoading(false);
		// if (response?.error) {
		// 	setErrorMsg(
		// 		typeof response.error === 'string'
		// 			? response.error
		// 			: response.error.message
		// 	);
		// 	return;
		// }
		// props.setPaymentCompleted(response?.success ? true : false);
	};
	// const content = JSON.parse(props.content)
	return (
		<>
			{props.isSender &&
				<MonetizationOnIcon
					className="inputMessageIcon"
					style={{marginTop: '-5px'}}
					onClick={handleShow}
				/>
			}
			{!props.isSender &&
				<MonetizationOnIcon
					className="inputMessageIcon"
					style={{marginTop: '-5px'}}
					onClick={handleShow}
				/>
			}

			<Modal
				show={show}
				onHide={handleClose}
				size="xs"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header className="row mx-0 justify-content-center">
					<p className="text-center k-semibold-20 w-100 pt-2">Stripe payment</p>
				</Modal.Header>
				<Modal.Body style={{marginLeft:'0px'}}>
					<Elements stripe={stripePromise}>
						<CheckoutForm
							setPaymentCompleted={''}
							dataDiscussion={props.content}
							handleClose={handleClose}
						/>
					</Elements>
				</Modal.Body>
			</Modal>
		</>
	);
}

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(api.stripePromise);
  }
  return stripePromise;
};

export default getStripe;