import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import user_add from '../../assets/profil/user_add.svg';
import liste_damis from '../../assets/liste_damis.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import defaultProfile from '../../assets/profil/utilisateur.png';
import { sendNewRequestFriend } from '../../services/stores/actions/compte';
import Followup from '../Followup/Followup';

export function ModalDetailsUser(props) {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			{props.title && (
				<button
					// className="btn btn-yellow btn-rounded pointer-cursor k-regular-15"
					className="ml-auto btn-rounded btn-sm rounded-pill"
					variant="btn-rounded btn-sm rounded-pill"
					style={{
						fontFamily: 'KohinoorRegular',
						border: 'solid 1px #F7CC2A',
						fontSize: '15px !important'
					}}

					onClick={handleShow}>
					{props.custom && <img src={user_add} alt="" />} {props.title}
				</button>
			)}

			<Modal
				show={show}
				onHide={handleClose}
				size="md"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header className="row mx-0 justify-content-center">
					<img src={user_add} width={`50px`} height={`50px`} alt="" />
					<p className="text-center k-semibold-20 w-100 pt-2">{props.title}</p>
				</Modal.Header>

				<Modal.Footer>
					<div className="col-12">
						{props.status === 'REFUSED' ? null : (
							<button
								className="btn btn-danger btn-rounded k-regular-15 mr-2 btn-block"
								style={{ border: 'none' }}
								onClick={() => {
									if (props.isSender) {
										props.onClickSender();
									}
									if (!props.isSender) {
										props.onClickRefuse();
									}
									handleClose();
								}}>
								{props.button}
							</button>
						)}

						{!props.isSender && props.title !== 'Friend' ? (
							<button
								className="btn btn-yellow btn-rounded k-regular-15 mr-2 btn-block"
								style={{ border: 'none' }}
								onClick={() => {
									props.onClickValidate();
									handleClose();
								}}>
								Accept a request
							</button>
						) : !props.isSender && props.title === 'Request refused' ? (
							<button
								className="btn btn-yellow btn-rounded k-regular-15 mr-2 btn-block"
								style={{ border: 'none' }}
								onClick={() => {
									props.onClickValidate();
									handleClose();
								}}>
								Send request
							</button>
						) : null}
						<button
							className="btn btn-light btn-rounded k-regular-15 btn-block"
							style={{ border: 'none' }}
							onClick={handleClose}>
							Cancel
						</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export function ModalDetailsUserFollowing(props) {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			{props.title && (
				<span className="pointer-cursor k-semibold-15" onClick={handleShow}>
					{props.title} <span className="k-regular-15">{props.label}</span>
				</span>
			)}

			<Modal
				show={show}
				onHide={handleClose}
				size={`${props.isMobile ? 'sm' : 'md'}`}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<Modal.Header className=" k-semibold-20" closeButton>
					List of {props.label}
				</Modal.Header>
				<Modal.Body>
					<div className="col">
						<div className="row mx-0 justify-content-center">
							<img src={liste_damis} width={`50px`} height={`50px`} alt="" />
						</div>
						<div className="col mx-0 w-100">
							<InfiniteScroll
								dataLength={props.tabsLength}
								hasMore={true}
								height={300}>
								{props.tabs.map((following, index) => {
									const value = [];
									return (
										<div
											className="item d-flex align-items-center mt-2 pointer-cursor"
											key={value.id}>
											<div className="image">
												<img
													src={
														props.isFollowing
															? following?.user_full.profil
																? following?.user_full.profil
																: defaultProfile
															: following?.sender_full.profil
															? following?.sender_full.profil
															: defaultProfile
													}
													alt="..."
													style={{ width: '32px', height: '32px' }}
													className="img-fluid rounded-circle mr-2"
												/>
											</div>
											<div
												className="text mr-auto d-flex flex-column pointer-cursor"
												onClick={() => {
													if (props.isFollowing) {
														props.props.props.history.push({
															pathname: `/details-user/${following.user_full.pseudo}`,
															id: following.user_full.id,
														});
													} else {
														props.props.props.history.push({
															pathname: `/details-user/${following.sender_full.pseudo}`,
															id: following.user_full.id,
														});
													}
												}}>
												<span className="k-semibold-15">
													{props.isFollowing
														? `${following.user_full.firstName} ${following.sender_full.lastName}`
														: `${following.sender_full.firstName} ${following.sender_full.lastName}`}
												</span>
												<small className="text-muted .k-regular-13 overflow-hidden">
													{props.isFollowing
														? `@${following.user_full.pseudo.substring(0, 30)}`
														: `@${following.sender_full.pseudo.substring(
																0,
																30
														  )}`}
												</small>
											</div>

											{following.is_friends && (
												// <Button
												// 	className="text ml-auto"
												// 	variant="btn-rounded btn-sm rounded-pill"
												// 	style={{
												// 		fontFamily: 'KohinoorRegular',
												// 		border: 'solid 1px #F7CC2A',
												// 	}}
												// 	onClick={() => {
												// 		if (this.props.isConnect) {
												// 			if (following.is_friends === false) {
												// 				sendNewRequestFriend(following.id).then(() => {
												// 					props.setStateOfParent();
												// 				});
												// 			}
												// 		} else {
												// 			this.props.history.push(`/login`);
												// 		}
												// 	}}>
												// 	S'abonner
												// </Button>
												<Followup
													isConnected={this.props.isConnected}
													id={following.id}
													setStateOfParent={props.setStateOfParent}
													isFriend={following.is_friends}
													key={index}
												></Followup>
											)}
										</div>
									);
								})}
							</InfiniteScroll>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
