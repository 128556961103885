import {
	GET_ALL_DISCUSSION,
	MESSAGE_RECEIVED,
	SET_MESSAGES,
	SET_SELECTED_DISCUSSION,
	SHOW_MODAL_COMMENTAIRE,
	GET_PARENT_ID,
	SET_UPDATE_DISCUSSION,
	REFRESH_DISCUSSION,
	SET_SOCKET_CONNECTED
} from './actionType';
import WS from './api';
import { api } from '../../../res/constants';

/**
 * Get all discussions for the user
 *
 * @param {*} params The query HTTP params
 * @returns
 */
export const getAllDicussions = (page = 1, par_page = 15) => {
	const params = new URLSearchParams();
	params.append('page', page);
	params.append('par_page', par_page);

	console.log("page du discu numero:",page);
	console.log("item par page",par_page);

	return WS.axiosSecureGet(
		`${api.getAllDiscussions}?${params.toString()}`
	).then((res) => {
		return res.data;
	});
};
/**
 * Get all messages for the discussions
 *
 * @param {*} params The query HTTP params
 * @returns
 */
export const getAllMessagesFor = (discussionId, page = 1, par_page = 15) => {
	const params = new URLSearchParams();
	params.append('page', page);
	params.append('par_page', par_page);

	console.log("page numero:",page);
	console.log("message par page",par_page);

	// const url = `${api.getAllDiscussions}/${discussionId}/messages?${params.toString()}`;
  	// console.log('URL de la requête:', url);

	return WS.axiosSecureGet(
		`${api.getAllDiscussions}/${discussionId}/messages?${params.toString()}`
	).then((res) => {
		return res.data;
	});
};

export const createNewDiscussion = (data) => {
	const fd = new FormData();
	fd.append('message', data.message);
	for (let i = 0; i < data.receivers.length; i++) {
		fd.append(`receivers[${i}]`, data.receivers[i]);
	}
	if(data.files) {
		for (let i = 0; i < data.files.length; i++) {
			fd.append(`files[${i}]`, data.files[i]);
		}
	}
	fd.append('sender', data.sender);
	fd.append('subject', data.subject);

	return WS.axiosPostFormDataWithToken(fd, `/messages/create-discussion`)
		.then((res) => {
			return res.data;
		});
}

// ACTION MESSAGE RECEIVED
export const newMessage = (data) => {
	return {
		type: MESSAGE_RECEIVED,
		data,
	};
};

export const showAllDiscussion = (data) => {
	return {
		type: GET_ALL_DISCUSSION,
		data,
	};
};

export const showMessages = (data) => {
	return {
		type: SET_MESSAGES,
		data,
	};
};

export const setSelectedDiscussion = (data) => {
	return {
		type: SET_SELECTED_DISCUSSION,
		data,
	};
};

export const setShowDialog = (data) => {
	return {
		type: SHOW_MODAL_COMMENTAIRE,
		data,
	};
};

export const setParentId = (data) => {
	return {
		type: GET_PARENT_ID,
		data,
	};
};

export const setUpdateDiscussion = (data) => {
	return {
		type: SET_UPDATE_DISCUSSION,
		data,
	};
};

export const refreshDiscussion = (data) => {
	return {
		type: REFRESH_DISCUSSION,
		data
	}
};

export const setSocketConnected = (data) => {
	return {
		type: SET_SOCKET_CONNECTED,
		data
	}
}