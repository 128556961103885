import React from 'react';
import './Message.css';

import yona2 from '../../assets/yona2.jpg';
import defaultProfile from '../../assets/profil/utilisateur.png';
import GoogleTraductor from '../../assets/icons/google-traductor.png';
import  { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import zelda from '../../assets/zelda.jpg';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import { ModalStripe } from '../../components/Modal/ModalStripe';
import VideoLooper from 'react-video-looper';
import { Translate } from '@google-cloud/translate';
import { GTranslate, MoreVertOutlined, DeleteOutlineOutlined, ReplyOutlined } from '@material-ui/icons'
import { Popover, Overlay } from 'react-bootstrap';
import { api } from '../../res/constants';
import secret from '../../assets/data/secret.json'
import ModalPaypal from '../Modal/ModalPaypal';
import WS from '../../services/stores/actions/api';
import ModalPayMessage from '../Modal/ModalPayMessage';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../services/stores/selectors/selectors';
import RabbitmqClient from '../../utils/rabbitmq';

const Message = ({
	isSentByCurrentUser = false,
	userImg = defaultProfile,
	content = null,
	time = null,
	files = null,
	messageId='',
	refreshMessage,
}) => {

	const isPaid = false;
	const [message, setMessage] = useState('')
	const [messageFiles, setMessageFiles] = useState()
	const [show, setShow] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [target, setTarget] = useState(null);
	const popoverRef = useRef(null)
	const userConnected = useSelector(currentUserSelector)
	const acceptPayment = () => {
		const userObject = JSON.parse(content);
		// stripe.confirmCardPayment(response.data, {
		// 	payment_method: userObject.paid,
		// 	// payment_method: paymentMethodObj,
		// })
		// .then(
		// 	(response) => {
		// 		console.log(`response`, response);
		// 		// if (response.error) {
		// 		// } else {
		// 		// }
		// 		// history.push({
		// 		// 	pathname: '/ValidationPaymentCard',
		// 		// 	state: {
		// 		// 		status: response.error
		// 		// 			? 'Error'
		// 		// 			: response?.paymentIntent?.status,
		// 		// 	},
		// 		// });
		// 	},
		// 	(error) => {
		// 		console.log(`Error`, error);
		// 	}
		// );
	}

	useEffect(() => {
		setMessage(content)
	}, [messageId])

	const handleButtonClick = (event) => {
		setTarget(event.target);
		togglePopover();
	  };

	const translateText = async (text, targetLanguage='ru') => {
		try {
		  setIsLoading(true)
		  const message = new FormData()
		  message.append('text', text)
		  message.append('lang', userConnected.userConnected?.translateLang?.alpha2 ? userConnected.userConnected?.translateLang?.alpha2 : 'fr')

		  WS.axiosPostFormDataWithToken(
			message,
			`${api.translate}`
		  ).then(response => {
			setMessage(response.data.replace('&#39;', "'"))
			setIsLoading(false)
		  }).catch(err => console.log('ERR_TRANSLATE ', err))

		//   Faites quelque chose avec la traduction
		} catch (error) {
		  console.error('Erreur de traduction:', error);
		}
	};

	const togglePopover = () => {
		setShow(!show)
	}


	return isSentByCurrentUser ? (
		<div className="col">
			<div className="row mx-0 justify-content-center">
				<p className="text-muted k-regular-13">{time}</p>
			</div>
			{!content?.includes('"data_pi":') &&
				<div className="col messageOwner" style={{marginLeft :'25px'}}>
					<div className="messageContainer justifyEnd flex-column align-items-end">
							{ content?.trim().length !== 0 && (
								<>
								<div className='d-flex align-items-center' style={{ width: '100%', justifyContent: 'flex-end' }}>
									<div className="messageBox backgroundYellow">
										<p className="messageText k-regular-15">{message}</p>
									</div>
									<div>
										<div onClick={handleButtonClick}>
											<MoreVertOutlined 
												style={{ cursor: 'pointer', fontSize: '20px', color: 'gray' }}
											></MoreVertOutlined>	
										</div>
										<Overlay
											show={show}
											target={target} 
											rootClose={true}
											onHide={() => setShow(false)}
											placement="bottom"
											container={document.body}
											containerPadding={20}
										>
											<Popover id="popover-contained" ref={popoverRef}>
												<Popover.Content>
													<div style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center'
													}}>
														<div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', margin: '0px 4px' }}>
															<ReplyOutlined style={{fontSize: '1rem', marginRight: '1px'}}></ReplyOutlined>
															{/* <span>reply</span> */}
														</div>
														<div 
															style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', margin: '0px 4px' }}
															onClick={() => translateText(message)}
															>
															<GTranslate 
																style={{fontSize: '1rem', marginRight: '1px'}}
																></GTranslate>
															{/* <span>translate</span> */}
														</div>
														<div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', margin: '0px 4px' }}>
															<DeleteOutlineOutlined style={{fontSize: '1rem', marginRight: '1px'}}></DeleteOutlineOutlined>
															{/* <span>delete</span> */}
														</div>
													</div>
												</Popover.Content>
											</Popover>
										</Overlay>
									</div>
								</div>
								</>
							)}
						{files && files.map((file, index) => (
							<div style={{width: '60%'}}>
								{
									file.type === "IMAGE" && (
										<>
											<img className='messageFile' src={file.url} key={index} alt='uploaded' />
										</>
									)
								}
								{
									file.type === "VIDEO" && (
										<>
											<video className='messageFile' controls controlsList='nodownload'>
												<source src={file.url} key={index} type="video/mp4" />
												<source src={file.url} key={index} type="video/avi" />
											</video>
										</>
									)

								}
								{
									file.type === "AUDIO" && (
										<>
											<audio className='audioFile' controls>
												<source src={file.url} key={index} type="audio/mp3" />
											</audio>
										</>
									)

								}
							</div>
						))}
						
					</div>

					{/* <div className="messageInfo mt-1">
							<img src={zelda} alt="zelda" className='messageImage' />
						</div> */}
				</div>
			}
			{content?.includes('"data_pi":') &&
				<div className="col messageOwner" style={{marginLeft :'25px'}}>
					<div className="messageContainer justifyEnd flex-column align-items-end">
						<div className='text-center px-3' style={{backgroundColor: '#F5F5F5', borderRadius: '3px', display: 'flex', flexDirection: 'column', alignItems: 'start', color: 'gray', cursor: 'not-allowed'}}>
							<p style={{ width: '100%', borderBottom: '1px solid #000', margin: '8px 0px' }}>REQUEST</p>
							<ModalPayMessage
								setDataUpdateParent={refreshMessage}
								messageId={messageId}
								isPaid={JSON.parse(content).status}
								// isPaid={Math.random() < 0.5 ? true : false}
								label="RequestPaid"
								panier_id={JSON.parse(content).data_pi}
								content={JSON.parse(content)}
								button="danger"
								client_id='642592690077480ba6d0e885'
								// cdm={(data) => this.setPI(data) }
								isSender={true}
								adresseClient={{
									price: JSON.parse(content).price,
									description: JSON.parse(content).description,
									client_id: JSON.parse(content).client_id,
									paymentType: JSON.parse(content).paymentType
								}}
							/>
						</div>
					</div>
				</div>
			}
		</div>
	) : (
		<div className="MessageBox col">
			<div className="">
				<p className="text-muted k-regular-13 text-center">{time}</p>
			</div>
			<div className="row">
				<img
					src={userImg ? userImg : defaultProfile}
					width="25px"
					height="25px"
					className="rounded-circle"
					style={{marginLeft:'10px'}}
				/>
				{!content?.includes('"data_pi":') &&
					<div className="col messageReceive">
						<div className="messageContainer justifyStart">
							{ content?.trim().length !== 0 && (
								<>
									<div className='d-flex align-items-center' style={{ width: '100%', justifyContent: 'start' }}>
										<div className="messageBox backgroundLight">
											<p className="messageText k-regular-15">{message}</p>
										</div>
									<div>
									<div onClick={handleButtonClick}>
										<MoreVertOutlined 
											style={{ cursor: 'pointer', fontSize: '20px', color: 'gray' }}
										></MoreVertOutlined>	
									</div>
									<Overlay
										show={show}
										target={target} 
										rootClose={true}
										onHide={() => setShow(false)}
										placement="bottom"
										container={document.body}
										containerPadding={20}
										>
											<Popover id="popover-contained" ref={popoverRef}>
												<Popover.Content>
													<div style={{
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center'
													}}>
														<div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', margin: '0px 4px' }}>
															<ReplyOutlined style={{fontSize: '1rem', marginRight: '1px'}}></ReplyOutlined>
															{/* <span>reply</span> */}
														</div>
														<div 
															style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', margin: '0px 4px' }}
															onClick={() => translateText(message)}
															>
															<GTranslate 
																style={{fontSize: '1rem', marginRight: '1px'}}
																></GTranslate>
															{/* <span>translate</span> */}
														</div>
														<div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', margin: '0px 4px' }}>
															<DeleteOutlineOutlined style={{fontSize: '1rem', marginRight: '1px'}}></DeleteOutlineOutlined>
															{/* <span>delete</span> */}
														</div>
													</div>
												</Popover.Content>
											</Popover>
										</Overlay>
									</div>
								</div>
								</>
							)}
							{files && files.map((file) => (
								<div style={{width: '60%'}} key={file.fileId}>
									{
										file.type === "IMAGE" && <img className='messageFile' src={file.url} alt='uploaded' />
									}
									{
										file.type === "VIDEO" && (
											<video className='messageFile' controls controlsList='nodownload'>
												<source src={file.url} type="video/mp4" />
												<source src={file.url} type="video/avi" />
											</video>
										)

									}
									{
										file.type === "AUDIO" && (
											<audio className='audioFile' controls>
												<source src={file.url} type="audio/mp3" />
											</audio>
										)
									}
								</div>
							))}
						</div>
					</div>
				}
				{content?.includes('"data_pi":') &&
					<div className="col messageReceive">
						<div className="messageContainer justifyStart">
							<div className='text-center px-3' style={{backgroundColor: '#F5F5F5', borderRadius: '3px', display: 'flex', flexDirection: 'column', alignItems: 'start', cursor: 'pointer'}}>
							<p style={{ width: '100%', borderBottom: '1px solid #000', margin: '8px 0px' }}>PAY</p>
									<ModalPayMessage
										setDataUpdateParent={refreshMessage}
										messageId={messageId}
										// isPaid={JSON.parse(content).status}
										isPaid={JSON.parse(content).status}
										content={JSON.parse(content)}
										label="RequestPaid"
										panier_id={JSON.parse(content).data_pi}
										button="danger"
										client_id='642592690077480ba6d0e885'
										// cdm={(data) => this.setPI(data) }
										isSender={false}
										adresseClient={{
											price: JSON.parse(content).price,
											description: JSON.parse(content).description,
											client_id: JSON.parse(content).client_id,
											paymentType: JSON.parse(content).paymentType
										}}
									/>
							</div>
						</div>
					</div>
				}
			</div>
		</div>
	);
};
export default Message;