//#region CLASS COMPONENT
import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Message from '../Message/Message';
import BounceLoader from 'react-spinners/BounceLoader';
import dayjs from 'dayjs';
import { momentGetDiff } from '../../services/stores/actions/size';
import { connect } from 'react-redux';

import defaultProfile from '../../assets/profil/utilisateur.png';


import {
	getAllMessagesFor,
	// getAllMessagesFor,
	// newMessage,
	// setSelectedDiscussion,
	// showAllDiscussion,
	showMessages,
	setUpdateDiscussion,
} from '../../services/stores/actions/chat';

export class InfiniteScrolling extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// items: Array.from({ length: 20 }),
			messageData: [],
			hasMore: true,
			isLoading: false,
			page: 1, 
		};
	}

	fetchMoreData = () => {

		const  discussionId  = this.props.discussion?.discussion?.id;
		const nextPage = this.state.page + 1;
		// const { page, par_page } = this.state;

		getAllMessagesFor(discussionId, nextPage)
			.then((data) => {
				console.log("Call API---->",data);				

				// Convert the data into an array of messages
				const newMessages = data.data.content.map((message) => {
					return ({
						id: message.id,
					  img: message.sender.profile ? message.sender.profil : defaultProfile,
					  content: message.message,
					  files: message.files,
					  isSentByCurrentUser:this.props.userConnected && message.sender.id === this.props.userConnected.id,
					  time: message.createdAt,
					})
				});
				// Append new messages to the existing messages array
				console.log('sms---> ', this.props.messages);
				const updatedMessages  = [...newMessages, ...this.props.messages ];
				console.log("updated message---->",updatedMessages);
				this.props.showMessages(updatedMessages);
				this.scrollToLastMsg();
				this.setState({ page: nextPage });
				// this.setState({ page: page + 1 });		

			})
			.catch((error) => {
				console.log('Error fetching more messages:', error);
			}
		);
		console.log(this.props.messages);
		// console.log(this.props.discussion.discussion.id);
	}

	componentDidMount(){
		this.fetchMoreData();
	}

	scrollToLastMsg() {
		let msgLB = document.getElementById('messageLastBottom')
		if (msgLB) {
			msgLB.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
		}
	}

	render() {
		// const height = window.innerHeight - 195;
		const height = window.innerHeight - 150;
		return (
			<div style={{ width: '100%', height: '100%' }}>
				AAAAAAAAAAAABBBBBBBBBB
				{!this.props.isLoadingMessage ? (
					<InfiniteScroll
						dataLength={this.props.messages.length}
						style={{overflowX : 'hidden', paddingBottom: '45px'}}
						className="mb-5"
						next={()=>this.fetchMoreData()}
						// next={this.fetchMoreData}
						hasMore={true}
						height={height}
						loader={
							this.state.isLoading ? (
								<div className="spinner" key={0}>
								<BounceLoader color={'orange'} loading={true} size={100} />
								</div>
							) : null
							}
						// style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
						inverse={true}
						refreshFunction={() => this.props.refreshMessage}>
						{/* {[...this.props.messages].reverse().map((item, index) => ( */}
						{[...this.props.messages].map((item, index) => (
							<>
								<Message
									key={item.id || index}
									refreshMessage={this.props.refreshMessage}
									messageId={item.id}
									userImg={item.img}
									isSentByCurrentUser={item.isSentByCurrentUser}
									time={momentGetDiff(item.time)}
									content={item.content}
									files={ item.files }
								/>
								<div style={{height: '30px'}}></div>
							</>
						))}
						<div id="messageLastBottom"></div>
					</InfiniteScroll>
					) : (
						<div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
							<div className="spinner d-flex align-items-center justify-content-center" style={{ width: '100%', height: '100%' }} key={0}>
								<BounceLoader color={'orange'} loading={true} size={100} />
							</div>
						</div>
					)
				}
			</div>
		);
	}
}


const mapStateToProps = (state) => {
	return {
	  messages: state.chatReducers.messages,
	  profil: state.compteReducers.userConnected,
	  discussion: state.chatReducers.selectedDiscussion,
	  userConnected : state.compteReducers.userConnected,

	};
  };
  
  const mapDispatchToProps = (dispatch) => {
	return {
	  updateDiscussion: (data) => dispatch(setUpdateDiscussion(data)),
	  showMessages: (data) => dispatch(showMessages(data)),
	};
  };


export default connect(mapStateToProps, mapDispatchToProps)(InfiniteScrolling);

//#endregion

//#region class Component 2

// import React, { Component } from 'react';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import Message from '../Message/Message';
// import BounceLoader from 'react-spinners/BounceLoader';
// import dayjs from 'dayjs';
// import { momentGetDiff } from '../../services/stores/actions/size';
// import { connect } from 'react-redux';

// import defaultProfile from '../../assets/profil/utilisateur.png';


// import {
// 	getAllMessagesFor,
// 	showMessages,
// 	setUpdateDiscussion,
// } from '../../services/stores/actions/chat';
// import axios from 'axios';

// export class InfiniteScrolling extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			messageData: [],
// 			hasMore: true,
// 			loading: false,
// 			page: 1, 
// 		};
// 	}

// 	fetchData = async () => {
// 		try {
// 			this.setState({loading:true});
// 			const {page,messageData} = this.state;
// 			const  discussionId  = this.props.discussion?.discussion?.id;
// 			const response = await axios.get(
// 				`https://ws-dev.mybeedoo.com/api/v1/discussions/${discussionId}/messages?page=${page}&par_page=15`,
// 				{
// 					headers:{
// 						Authorization: `Bearer ${localStorage.getItem('token')}`,
// 					},
// 				}
// 			);
// 			console.log("reponse API---->",response);
// 			const newMessages = response.data.data.content.map((message) => {
// 				return({
// 					id: message.id,
// 					img: message.sender.profile ? message.sender.profil : defaultProfile,
// 					content: message.message,
// 					files: message.files,
// 					isSentByCurrentUser:this.props.userConnected && message.sender.id === this.props.userConnected.id,
// 					time: message.createdAt,
// 				})
// 			});
// 			console.log("sms---->", this.props.messages);

// 			// const updatedMessages = [...newMessage, this.props.messages];
// 			// console.log("updated message ---->", updatedMessages);
// 			// this.props.showMessages(updatedMessages);
// 			this.setState({
// 				messageData: [...newMessages, ...this.props.messages],
// 				loading: false,
// 			});

// 			this.props.showMessages(messageData);

// 			this.setState((prevState) => ({
// 				page: prevState.page + 1,
// 			}));

// 			if(newMessages.length === 0){
// 				this.setState({hasMore:false});
// 			}
// 		} catch (error) {
// 			console.error('Error fetching data:', error);
//       this.setState({ loading: false });
// 		}
// 	}

// 	componentDidMount() {
//     this.fetchData();
//   }

// 	render(){
// 		const { messageData, hasMore } = this.state;
// 		const height = window.innerHeight - 150;
// 		return(
// 			<div style={{ width: '100%', height: '100%' }}>
// 				{!this.props.isLoadingMessage ? (
// 					<InfiniteScroll
// 						dataLength={messageData.length}
// 						style={{overflowX : 'hidden', paddingBottom: '45px'}}
// 						className="mb-5"
// 						next={this.fetchMoreData}
// 						hasMore={hasMore}
// 						height={height}
// 						loader={
// 							this.state.isLoading ? (
// 								<div className="spinner" key={0}>
// 								<BounceLoader color={'orange'} loading={true} size={100} />
// 								</div>
// 							) : null
// 							}
// 						inverse={true}
// 						refreshFunction={() => this.props.refreshMessage}
// 					>
// 						{messageData.map((item, index) => (
// 							<>
// 								<Message
// 									key={item.id || index}
// 									refreshMessage={this.props.refreshMessage}
// 									messageId={item.id}
// 									userImg={item.img}
// 									isSentByCurrentUser={item.isSentByCurrentUser}
// 									time={momentGetDiff(item.time)}
// 									content={item.content}
// 									files={ item.files }
// 								/>
// 								<div style={{height: '30px'}}></div>
// 							</>
// 						))}
// 						<div id="messageLastBottom"></div>
// 					</InfiniteScroll>
// 					) : (
// 						<div className='d-flex align-items-center justify-content-center' style={{ height: '100%' }}>
// 							<div className="spinner d-flex align-items-center justify-content-center" style={{ width: '100%', height: '100%' }} key={0}>
// 								<BounceLoader color={'orange'} loading={true} size={100} />
// 							</div>
// 						</div>
// 					)
// 				}
// 			</div>
// 		)
// 	}



// }


// const mapStateToProps = (state) => {
// 	return {
// 	  messages: state.chatReducers.messages,
// 	  profil: state.compteReducers.userConnected,
// 	  discussion: state.chatReducers.selectedDiscussion,
// 	  userConnected : state.compteReducers.userConnected,

// 	};
//   };
  
//   const mapDispatchToProps = (dispatch) => {
// 	return {
// 	  updateDiscussion: (data) => dispatch(setUpdateDiscussion(data)),
// 	  showMessages: (data) => dispatch(showMessages(data)),
// 	};
//   };


// export default connect(mapStateToProps, mapDispatchToProps)(InfiniteScrolling);
//#endregion