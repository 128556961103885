import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import React from "react";
import { Form, Modal } from "react-bootstrap";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import FiberNewOutlined from "@material-ui/icons/FiberNewRounded"
import paypalLogo from '../../assets/payment/paypal-226456.svg'
import cb from '../../assets/payment/cb-card.jpeg'
import visa from '../../assets/payment/visaLogo.jpeg'
import ae from '../../assets/payment/american-226445.svg'
import mc from '../../assets/payment/mastercard-226450.svg'
import CheckoutFormPaypal from "../Payment/CheckoutFormPaypal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { api } from "../../res/constants";
import CheckoutForm from "../Payment/CheckoutForm";
import { buyPrivateProduct, messageRequestPaid } from "../../services/stores/actions/orders";
import '../Message/Message.css'
import { toast } from 'react-toastify';

// const steps = ["Choose other payment type", "Pay the request"];
const steps = ["Choose method of payment", "Pay the request"];
let stripePromise;
const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(api.stripePromise);
    }
    return stripePromise;
  };

export default function ModalPayMessage(props) {
  const [activeStep, setActiveStep] = useState(0);

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [panierId, setPanierId] = useState('');
  const [messageId, setMessageId] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedValue, setSelectedValue] = useState('PAYPAL')
  const stripe = getStripe();
  const handleSelectedValue = (event) => {
    setSelectedValue(event.target.value)
}

  const handleNext = () => {
    if((activeStep === 0) && props.adresseClient) {
      let data = {...props.adresseClient, paymentType: 'CARD'}
      setIsLoading(true)
      buyPrivateProduct(
        {
          panierId,
          messageId,
          paymentType: selectedValue
        }
      ).then(
        (response) => {
          setOrderId(response.data.data)
          setIsLoading(false)
        },
        (error) => {
          if(error.message.includes('code 400')) {
            toast.error('Payment already done')
          }
          setIsLoading(false)
        }
      );
    }
    
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const parseStatus = (state) => {
    if(state === 'ECHOUEE') {
      return 'ECHEC'
    } else if(state === 'CREE') {
      return 'CREATED'
    } else {
      return 'NEW'
    }
  }

  useEffect(() => {
    setPanierId(props.panier_id)
    setMessageId(props.messageId)
  }, [props.panier_id, props.messageId])

  return (
    <div onClick={!props?.isSender ? handleShow: null} style={{cursor: 'pointer'}}>
      <p className="mb-0" style={{ fontSize: '13px', textAlign: 'left' }}>Amount: {props.content.price} €</p>
      <p className="mb-1" style={{ fontSize: '13px', textAlign: 'left' }}>{props.content.description}</p>

      { props.isPaid === 'NOUVELLE' ? (
        <>
          <div className="d-flex align-items-center justify-content-center"  style={{ fontSize: '13px' }}> 
            <span style={{ marginRight: "5px" }}><span style={{ fontWeight: '500' }}>Status</span> : Paid</span>
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-center" style={{ fontSize: '13px', cursor: 'pointer', padding: '5px 0px' }}>
            <span style={{ marginRight: "5px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}><span style={{ fontWeight: '500' }}>Status</span> : { parseStatus(props?.isPaid) }</span>
            <FiberNewOutlined
              className="inputMessageIcon"
              style={{ marginTop: "-5px", color: '#42B5FF' }}
            />
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            size="xs"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      Payment closed
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleClose}>Close</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>

                    <div className="text-center mt-0 mb-2">Amount: {props.adresseClient.price} €</div>
                      {activeStep === 0 ? (
                        <>
                          <Form>
                            <Form.Group controlId="methodPayment" className="mb-3">
                              <Form.Label>Choose method of payment</Form.Label>
                              <div>
                                <div
                                  style={{
                                    marginBottom: "15px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Form.Check
                                    type="radio"
                                    name="methodPayment"
                                    id="paypalOption"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    label={
                                      <img
                                        width={"100px"}
                                        style={{ cursor: "pointer" }}
                                        src={paypalLogo}
                                        alt="Paypal"
                                      />
                                    }
                                    value="PAYPAL"
                                    defaultChecked={selectedValue === 'PAYPAL'}
                                    onChange={handleSelectedValue}
                                  />
                                </div>
                                <div
                                  style={{ paddingTop: "10px", cursor: "pointer" }}
                                >
                                  <Form.Check
                                    type="radio"
                                    name="methodPayment"
                                    defaultChecked={selectedValue === 'CARD'}
                                    style={{
                                      width: "100px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    id="masterCardOption"
                                    label={
                                      <div className="d-flex align-items-center justify-content-center">
                                        <img
                                          width={"50px"}
                                          height={"50px"}
                                          style={{
                                            objectFit: "contain",
                                            marginRight: "5px",
                                            cursor: "pointer",
                                          }}
                                          src={visa}
                                          alt="Card"
                                        />
                                        <img
                                          width={"50px"}
                                          height={"50px"}
                                          style={{
                                            objectFit: "contain",
                                            marginRight: "5px",
                                            cursor: "pointer",
                                          }}
                                          src={cb}
                                          alt="Visa"
                                        />
                                        <img
                                          width={"50px"}
                                          height={"50px"}
                                          style={{
                                            objectFit: "contain",
                                            marginRight: "5px",
                                            cursor: "pointer",
                                          }}
                                          src={ae}
                                          alt="Visa"
                                        />
                                        <img
                                          width={"50px"}
                                          height={"50px"}
                                          style={{
                                            objectFit: "contain",
                                            cursor: "pointer",
                                          }}
                                          src={mc}
                                          alt="Visa"
                                        />
                                      </div>
                                    }
                                    value="CARD"
                                    onChange={handleSelectedValue}
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Form>
                        </>
                      ) : (
                        <>
                          {selectedValue === 'PAYPAL' && (
                              <>
                                  <CheckoutFormPaypal
                                      setDataUpdateParent={props.setDataUpdateParent}
                                      privateRequest={true}
                                      handleClose={() => handleClose()}
                                      secretId={orderId}
                                      // client_id={orderId}
                                  ></CheckoutFormPaypal>
                              </>
                          )}
                          {selectedValue === 'CARD' && (
                              <>
                                  <Elements stripe={stripePromise}>
                                      <CheckoutForm
                                          setPaymentCompleted={''}
                                          adresseClient={props.adresseClient}
                                          dataDiscussion={JSON.stringify(orderId)}
                                          handleClose={handleClose}
                                      />
                                  </Elements>
                              </>
                          )}
                        </>
                      )}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Other payment type
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {/* {isStepOptional(activeStep) && (
                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                          Skip
                        </Button>
                      )} */}

                      <Button onClick={handleNext}>
                        { isLoading ? (
                            <>
                                <div
                                    className="spinner-border spinner-border-sm text-dark"
                                    role="status">
                                </div>
                            </>
                        ) : (
                            <>
                                {activeStep === steps.length - 1 ? "Close" : "Continue"}
                            </>
                        ) }
                      </Button>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </Modal.Body>
          </Modal>
          </>
        ) }
      </div>
  );
}
